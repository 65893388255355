import React, { useEffect } from "react";
import Header from "./Header";
import "./AllCourses.css";
import logo from "./hassan-documents/bluelogo.png";
import thumbnail from "./hassan-documents/BG.jpg";
import allCourses1 from "./mahawir/VOCABULARY.jpg";
import exercises from "./UNIT1Images/exercises.jpg";
import GIFTSOFYOUTH from "./UNIT1Images/GIFTSOFYOUTH.jpg";
import INTRODUCTIONTOVOCABULARY from "./UNIT1Images/INTRODUCTIONTOVOCABULARY.jpg";
import Footer from "./Footer";
import { Link } from "react-router-dom";

import img1 from "./ACADEMYVIDEOS/vocabulary/INTRODUCTION TO VOCABULARY.jpg";
import img2 from "./ACADEMYVIDEOS/vocabulary/GIFTS OF YOUTH.jpg";
import img3 from "./ACADEMYVIDEOS/vocabulary/exercises.jpg";

import img4 from "./ACADEMYVIDEOS/functions/functions.jpg";
import img5 from "./ACADEMYVIDEOS/functions/expressing opinion.jpg";
import img6 from "./ACADEMYVIDEOS/functions/exercises.jpg";

import img7 from "./ACADEMYVIDEOS/grammar/grammars.jpg";
import img8 from "./ACADEMYVIDEOS/grammar/gerund and infinitive.jpg";
import img9 from "./ACADEMYVIDEOS/grammar/exercises.jpg";

import img10 from "./ACADEMYVIDEOS/writing/WRITING 2.jpg";
import img11 from "./ACADEMYVIDEOS/writing/DESCRIPTIVE PARAGRAPH.jpg";
import img12 from "./ACADEMYVIDEOS/writing/exercises.jpg";

import img13 from "./ACADEMYVIDEOS/tenses/ENGLISH TENSES 2.jpg";
import img14 from "./ACADEMYVIDEOS/tenses/irregular verbs.jpg";
import img15 from "./ACADEMYVIDEOS/tenses/exercises.jpg";

import pdfsrc from "./document/website-pdf.pdf";

function AllCourses() {
  const onButtonClick = () => {
    const pdfUrl = pdfsrc; // Path to your PDF file
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.download = "irregular-verbs-list.pdf"; // Specify the filename
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const moreInfo = () => {
    alert("This course is still in development, please check back later.");
  };

  useEffect(() => {
    // Scroll to the top of the page
    window.scrollTo(0, 0);
  }, []);

  window.onscroll = function () {
    const header = document.getElementById("All__courses__header");
    const headerLogo = document.querySelector("#All__courses__header img");

    if (window.scrollY > 50) {
      // Change 30 to your desired scroll distance

      headerLogo.style.width = "60px";
      // header.style.padding = "10px 100px";
      header.style.transition = "background 0.3s ease-in-out";
    } else {
      if (window.innerWidth < "668") {
        headerLogo.style.width = "60px";
        header.style.background = "white";
        // header.style.padding = "20px 100px";
      } else {
        headerLogo.style.width = "60px";
        header.style.background = "white";
      }
    }
  };
  useEffect(() => {
    const header = document.getElementById("All__courses__header");
    const headerLogo = document.querySelector("#All__courses__header img");
    headerLogo.style.width = "60px";
    header.style.background = "white";
    // header.style.padding = "20px 100px";
  }, []);
  function scrollToTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  //

  document.body.style.background = "#f1f1f1";
  return (
    <div className="allCourses">
      <header id="All__courses__header">
        <a href="https://toustousacademy.com/">
          <img src={logo} alt="" />
        </a>
        <ul>
          <a href="/" onClick={scrollToTop}>
            <li>Home</li>
          </a>
          <a href="/#about">
            <li>About</li>
          </a>
          <a href="/#feedback">
            <li>Feedback</li>
          </a>
        </ul>
      </header>
      <div className="courses__container">
        <div className="free__course">
          <h2>Vocabulary Lessons</h2>
          <p dir="rtl" className="introduction__p">
            قبل أن تبدأ في مشاهدة شرح الدرس، من المهم جدًا أن تشاهد التقديم
            أولاً. التقديم سيساعدك على فهم الأفكار الأساسية والمفاهيم العامة
            التي ستسهل عليك متابعة الشرح بطريقة أكثر فعالية. باتباع هذا الترتيب،
            ستضمن فهمًا أعمق وأسهل للدرس وتحقيق أفضل النتائج.
          </p>

          <div className="img__title__container">
            <div className="course__img__title">
              <img src={img1} alt="" />
              <div className="courseTitle">Introduction to Vocabulary</div>
              <p className="arabic__p">تقديم حول محور المفردات</p>
              <div className="vocabulary__buttons">
                <div className="moreInfo">Unit 1</div>
                <Link to="/free/vocabulary/introduction">
                  <button className="allCoursesGetStarted">Get started</button>
                </Link>
              </div>
            </div>

            <div className="course__img__title">
              <img src={img2} alt="" />
              <div className="courseTitle">Gifts of youth</div>
              <p className="arabic__p">مواهب الشباب</p>
              <div className="vocabulary__buttons">
                <div className="moreInfo">Unit 1</div>

                <Link to="/free/vocabulary/gifts-of-youth">
                  <button className="allCoursesGetStarted">Get started</button>
                </Link>
              </div>
            </div>

            <div className="course__img__title">
              <img src={img3} alt="" />
              <div className="courseTitle">Practice some exercises</div>
              <p className="arabic__p">ممارسة بعض التمارين</p>
              <div className="vocabulary__buttons">
                <div class="toast" id="toast">
                  This is a toast notification!
                </div>
                <div className="moreInfo">Unit 1</div>

                <Link to="/free/vocabulary/exercises">
                  <button className="allCoursesGetStarted">Get started</button>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="free__course">
          <h2>Functions Lessons</h2>
          {/* <p >
            قبل أن تبدأ في مشاهدة شرح الدرس، من المهم جدًا أن تشاهد التقديم
            أولاً. التقديم سيساعدك على فهم الأفكار الأساسية والمفاهيم العامة
            التي ستسهل عليك متابعة الشرح بطريقة أكثر فعالية. باتباع هذا الترتيب،
            ستضمن فهمًا أعمق وأسهل للدرس وتحقيق أفضل النتائج.
          </p> */}

          <div className="img__title__container">
            <div className="course__img__title">
              <img src={img4} alt="" />
              <div className="courseTitle">Introduction to Functions</div>
              <p className="arabic__p">تقديم حول وظائف اللغة</p>
              <div className="vocabulary__buttons">
                <div className="moreInfo">
                  Unit 1
                </div>
                <Link to="/free/functions/introduction">
                  <button className="allCoursesGetStarted">Get started</button>
                </Link>
              </div>
            </div>

            <div className="course__img__title">
              <img src={img5} alt="" />
              <div className="courseTitle">Expressing opinion</div>
              <p className="arabic__p">التعبير عن رأي.</p>
              <div className="vocabulary__buttons">
                <div className="moreInfo">Unit 1</div>

                <Link to="/free/functions/Expressing-opinion">
                  <button className="allCoursesGetStarted">Get started</button>
                </Link>
              </div>
            </div>

            <div className="course__img__title">
              <img src={img6} alt="" />
              <div className="courseTitle">Practice some exercises</div>
              <p className="arabic__p">ممارسة بعض التمارين</p>
              <div className="vocabulary__buttons">
                <div class="toast" id="toast">
                  This is a toast notification!
                </div>
                <div className="moreInfo">Unit 1</div>

                <Link to="/free/functions/exercises">
                  <button className="allCoursesGetStarted">Get started</button>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="free__course">
          <h2>Grammar Lessons</h2>
          {/* <p >
            قبل أن تبدأ في مشاهدة شرح الدرس، من المهم جدًا أن تشاهد التقديم
            أولاً. التقديم سيساعدك على فهم الأفكار الأساسية والمفاهيم العامة
            التي ستسهل عليك متابعة الشرح بطريقة أكثر فعالية. باتباع هذا الترتيب،
            ستضمن فهمًا أعمق وأسهل للدرس وتحقيق أفضل النتائج.
          </p> */}

          <div className="img__title__container">
            <div className="course__img__title">
              <img src={img7} alt="" />
              <div className="courseTitle">Introduction to Grammar</div>
              <p className="arabic__p">تقديم حول دروس القواعد</p>
              <div className="vocabulary__buttons">
                <div className="moreInfo">
                  Unit 1
                </div>
                <Link to="/free/grammar/introduction">
                  <button className="allCoursesGetStarted">Get started</button>
                </Link>
              </div>
            </div>

            <div className="course__img__title">
              <img src={img8} alt="" />
              <div className="courseTitle">Gerund and infinitive</div>
              <p className="arabic__p">اسم الفاعل والمصدر</p>
              <div className="vocabulary__buttons">
                <div className="moreInfo">Unit 1</div>

                <Link to="/free/grammar/Gerund-and-infinitive">
                  <button className="allCoursesGetStarted">Get started</button>
                </Link>
              </div>
            </div>

            <div className="course__img__title">
              <img src={img9} alt="" />
              <div className="courseTitle">Practice some exercises</div>
              <p className="arabic__p">ممارسة بعض التمارين</p>
              <div className="vocabulary__buttons">
                <div class="toast" id="toast">
                  This is a toast notification!
                </div>
                <div className="moreInfo">Unit 1</div>

                <Link to="/free/grammar/exercises">
                  <button className="allCoursesGetStarted">Get started</button>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="free__course">
          <h2>Writing Lessons</h2>
          {/* <p >
            قبل أن تبدأ في مشاهدة شرح الدرس، من المهم جدًا أن تشاهد التقديم
            أولاً. التقديم سيساعدك على فهم الأفكار الأساسية والمفاهيم العامة
            التي ستسهل عليك متابعة الشرح بطريقة أكثر فعالية. باتباع هذا الترتيب،
            ستضمن فهمًا أعمق وأسهل للدرس وتحقيق أفضل النتائج.
          </p> */}

          <div className="img__title__container">
            <div className="course__img__title">
              <img src={img10} alt="" />
              <div className="courseTitle">Introduction to Writing</div>
              <p className="arabic__p">تقديم حول محور الرايتين</p>
              <div className="vocabulary__buttons">
                <div className="moreInfo">
                  Unit 1
                </div>
                <Link to="/free/writing/introduction">
                  <button className="allCoursesGetStarted">Get started</button>
                </Link>
              </div>
            </div>

            <div className="course__img__title">
              <img src={img11} alt="" />
              <div className="courseTitle">Descriptive paragraph</div>
              <p className="arabic__p">فقرة وصفية</p>
              <div className="vocabulary__buttons">
                <div className="moreInfo">Unit 1</div>

                <Link to="/free/writing/Descriptive-paragraph">
                  <button className="allCoursesGetStarted">Get started</button>
                </Link>
              </div>
            </div>

            <div className="course__img__title">
              <img src={img12} alt="" />
              <div className="courseTitle">Practice some exercises</div>
              <p className="arabic__p">ممارسة بعض التمارين</p>
              <div className="vocabulary__buttons">
                <div class="toast" id="toast">
                  This is a toast notification!
                </div>
                <div className="moreInfo">Unit 1</div>

                <Link to="/free/writing/exercises">
                  <button className="allCoursesGetStarted">Get started</button>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="free__course">
          <h2>Tenses Lessons</h2>
          {/* <p >
            قبل أن تبدأ في مشاهدة شرح الدرس، من المهم جدًا أن تشاهد التقديم
            أولاً. التقديم سيساعدك على فهم الأفكار الأساسية والمفاهيم العامة
            التي ستسهل عليك متابعة الشرح بطريقة أكثر فعالية. باتباع هذا الترتيب،
            ستضمن فهمًا أعمق وأسهل للدرس وتحقيق أفضل النتائج.
          </p> */}

          <div className="img__title__container">
            <div className="course__img__title">
              <img src={img13} alt="" />
              <div className="courseTitle">Introduction to Tenses</div>
              <p className="arabic__p">تقديم حول محور الأزمنة</p>
              <div className="vocabulary__buttons">
                <div className="moreInfo">
                  Unit 1
                </div>
                <Link to="/free/tenses/introduction">
                  <button className="allCoursesGetStarted">Get started</button>
                </Link>
              </div>
            </div>

            <div className="course__img__title">
              <img src={img14} alt="" />
              <div className="courseTitle">How to memorize irregular verbs</div>
              <p className="arabic__p">كيفية حفظ الأفعال الشادة</p>
              <div className="vocabulary__buttons">
                <div className="moreInfo">Unit 1</div>

                <Link to="/free/tenses/irregular-verbs">
                  <button className="allCoursesGetStarted">Get started</button>
                </Link>
              </div>
            </div>

            <div className="course__img__title">
              <img src={img15} alt="" />
              <div className="courseTitle">Download irregular verbs list</div>
              <p className="arabic__p">تحميل قائمة الأفعال الشادة</p>
              <div className="vocabulary__buttons">
                <div class="toast" id="toast">
                  This is a toast notification!
                </div>
                <div className="moreInfo">Unit 1</div>

                <Link to="/free/irregular-verbs-pdf">
                  <button className="allCoursesGetStarted">Get started</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <center>
        <h1>Welcome to PDF Download Example</h1>
        <h3>Click the button below to download the PDF file</h3>
        <button onClick={onButtonClick}>Download PDF</button>
      </center> */}

      <Footer />
    </div>
  );
}

export default AllCourses;
