import React, { useEffect } from "react";
import logo from "./hassan-documents/bluelogo.png";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import "./CourseTenses.css";

import pdfUrls from "./hassan-documents/Present simple summary.pdf";
import CourseTensesVideo from "./ACADEMYVIDEOS/vocabulary/gifts of youth 2026 last edit.mp4";
function CourseTenses() {
  const onButtonClick = () => {
    const pdfUrl = pdfUrls;
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.download = "document.pdf"; // specify the filename
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  useEffect(() => {
    // Scroll to the top of the page
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    const header = document.getElementById("free__header");
    const headerLogo = document.querySelector("#free__header img");

    if (window.scrollY > 50) {
      // Change 30 to your desired scroll distance

      headerLogo.style.width = "60px";
      header.style.padding = "10px 100px";
      header.style.transition = "background 0.3s ease-in-out";
    } else {
      if (window.innerWidth < "668") {
        headerLogo.style.width = "60px";
        header.style.background = "white";
        header.style.padding = "20px 100px";
      } else {
        headerLogo.style.width = "60px";
        header.style.background = "white";
      }
    }
  }, []);
  window.onscroll = function () {
    const header = document.getElementById("free__header");
    const headerLogo = document.querySelector("#free__header img");

    if (window.scrollY > 50) {
      // Change 30 to your desired scroll distance

      headerLogo.style.width = "60px";
      header.style.padding = "10px 100px";
      header.style.transition = "background 0.3s ease-in-out";
    } else {
      if (window.innerWidth < "668") {
        headerLogo.style.width = "60px";
        header.style.background = "white";
        header.style.padding = "20px 100px";
      } else {
        headerLogo.style.width = "60px";
        header.style.background = "white";
      }
    }
  };
  function scrollToTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  return (
    <div className="CourseTenses">
      <header id="free__header">
        {" "}
        <a href="https://toustousacademy.com/">
          <img src={logo} alt="" />
        </a>
        <ul>
          <a href="/" onClick={scrollToTop}>
            <li>Home</li>
          </a>
          <a href="/#about">
            <li>About</li>
          </a>
          <a href="/#feedback">
            <li>Feedback</li>
          </a>
        </ul>
      </header>

      <div className="tenses__page">
        <h2 className="tenses__title">Simple present</h2>

        <div className="video__sections">
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseTensesVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Simple present - explanation</div>
          </div>
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseTensesVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Simple present - exercises</div>
          </div>
        </div>

        {/* present Continuous */}
        <h2 className="tenses__title"> Present Continuous</h2>

        <div className="video__sections">
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseTensesVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Present Continuous - explanation</div>
          </div>
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseTensesVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Present Continuous - exercises</div>
          </div>
        </div>

        {/* present perfect */}
        <h2 className="tenses__title">Present Perfect</h2>

        <div className="video__sections">
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseTensesVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Present Perfect - explanation</div>
          </div>
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseTensesVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Present Perfect - exercises</div>
          </div>
        </div>

        {/* <object data={pdfUrls} type=""></object> */}

        <h2 className="tenses__title">Simple Past</h2>

        <div className="video__sections">
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseTensesVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Simple Past - explanation</div>
          </div>
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseTensesVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Simple Past - exercises</div>
          </div>
        </div>

        {/* Past Continuous */}
        <h2 className="tenses__title"> Past Continuous</h2>

        <div className="video__sections">
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseTensesVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Past Continuous - explanation</div>
          </div>
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseTensesVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Past Continuous - exercises</div>
          </div>
        </div>

        {/* Past perfect */}
        <h2 className="tenses__title">Past Perfect</h2>

        <div className="video__sections">
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseTensesVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Past Perfect - explanation</div>
          </div>
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseTensesVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Past Perfect - exercises</div>
          </div>
        </div>

        {/* <object data={pdfUrls} type=""></object> */}

        <h2 className="tenses__title">Simple Future</h2>

        <div className="video__sections">
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseTensesVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Simple Future - explanation</div>
          </div>
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseTensesVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Simple Future - exercises</div>
          </div>
        </div>

        {/* Future Continuous */}
        <h2 className="tenses__title"> Future Continuous</h2>

        <div className="video__sections">
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseTensesVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Future Continuous - explanation</div>
          </div>
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseTensesVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Future Continuous - exercises</div>
          </div>
        </div>

        {/* Future perfect */}
        <h2 className="tenses__title">Future Perfect</h2>

        <div className="video__sections">
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseTensesVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Future Perfect - explanation</div>
          </div>
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseTensesVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Future Perfect - exercises</div>
          </div>
        </div>

        {/* <object data={pdfUrls} type=""></object> */}
      </div>

      {/* past */}
    </div>
  );
}

export default CourseTenses;
