import logo from "./logo.svg";
import "./App.css";
import Hero from "./Hero";
import Header from "./Header";
import About from "./About";
import StudentsOpinions from "./StudentsOpinions";
import Plan from "./Plan";
import Faq from "./Faq";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Home";
import Login from "./Login";
import AllCourses from "./AllCourses";
import BookLogin from "./BookLogin";
import Book from "./Book";
import FreeVocabulary from "./VocabularyExercises";

import VocabularyIntroduction from "./VocabularyIntroduction";
import VocabularyLesson from "./VocabularyLesson";
import VocabularyExercises from "./VocabularyExercises";
import FunctionsIntroduction from "./FunctionsIntroduction";
import FunctionsLesson from "./FunctionsLesson";
import FunctionsExercises from "./FunctionsExercises";
import GrammarIntroduction from "./GrammarIntroduction";
import GrammarLesson from "./GrammarLesson";
import GrammarExercises from "./GrammarExercises";
import WritingIntroduction from "./WritingIntroduction";
import WritingExercises from "./WritingExercises";
import TensesIntroduction from "./TensesIntroduction";
import Privacy from "./Privacy";
import Terms from "./Terms";
import DownloadIrrigularVerbs from "./DownloadIrrigularVerbs";
import TensesLesson from "./TensesLesson";
import WritingLesson from "./WritingLesson";
import CourseVocabulary from "./CourseVocabulary";
import CourseTenses from "./CourseTenses";
import BookSlide from "./BookSlide";
import CourseGrammar from "./CourseGrammar";
import CourseWriting from "./CourseWriting";
import CourseFunctions from "./CourseFunctions";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" exact  element={<Home />} />
          <Route path="/about"  element={<About />} />
          <Route path="/login"   element={<Login />} />
          <Route path="/start"   element={<BookLogin />} />
          <Route path="/AllCourses"   element={<AllCourses />} />
          <Route path="/Book"   element={<Book />} />
          <Route path="/privacy"   element={<Privacy />} />
          <Route path="/terms"   element={<Terms />} />
          <Route path="/Book/login"   element={<BookLogin />} />
          <Route path="/free/irregular-verbs-pdf"   element={<DownloadIrrigularVerbs />} />
          
          
          <Route path="/free/vocabulary/exercises"   element={<VocabularyExercises />} />
          <Route path="/free/vocabulary/introduction" element={<VocabularyIntroduction/>} />
          <Route path="/free/vocabulary/gifts-of-youth" element={<VocabularyLesson/>} />

          <Route path="/free/functions/exercises"   element={<FunctionsExercises />} />
          <Route path="/free/functions/introduction" element={<FunctionsIntroduction/>} />
          <Route path="/free/functions/Expressing-opinion" element={<FunctionsLesson/>} />

          <Route path="/free/grammar/introduction" element={<GrammarIntroduction/>} />
          <Route path="/free/grammar/Gerund-and-infinitive" element={<GrammarLesson/>} />
          <Route path="/free/grammar/exercises" element={<GrammarExercises/>} />


          <Route path="/free/writing/introduction" element={<WritingIntroduction/>} />
          <Route path="/free/writing/Descriptive-paragraph" element={<WritingLesson/>} />
          <Route path="/free/writing/exercises" element={<WritingExercises/>} />

          
          <Route path="/free/tenses/introduction" element={<TensesIntroduction/>} />
          <Route path="/free/tenses/irregular-verbs" element={<TensesLesson/>} />

          <Route path="/course/tenses" element={<CourseTenses/>} />
          <Route path="/course/grammar" element={<CourseGrammar/>} />
          <Route path="/course/writing" element={<CourseWriting/>} />
          <Route path="/course/functions" element={<CourseFunctions/>} />
          <Route path="/course/vocabulary" element={<CourseVocabulary/>} />
          

          <Route path="/book/deep-but-short" element={<BookSlide/>} />

          


          

          
          
        </Routes>
      </Router>
      {/* <Header/> */}
    </div>
  );
}

export default App;
