import React, { useState } from 'react';
import './Faq.css';
import faq from './FAQ.png'

const Faq = () => {
  const faqs = [
    { question: "شنو الهدف من منصة أكاديمية توستوس؟", answer: "الهدف من منصة أكاديمية توستوس هو توفر على التلميذ الوقت و الجهد عن طريق توفير دروس مدتها قصيرة ولكن مضمونها أعمق تحت شعار  بلا زيادة بلا نقصان، غتلقى غي داكشي لي عندك في الإمتحان." },
    { question: "واش غيخدم معانا الأستاذ طيلة السنة؟", answer: "أه بطبيعة الحال غيخدم معاكم الأستاذ من بداية السنة حتال آخر نهار قبل الإمتحان الوطني. " },
    { question: "كيفاش نقدر نخلص؟", answer: "المنصة كتقبل جميع أنواع الدفع. يعني تقدر تخلص من أي بنك قريب لك، وباش تعرف الطريقة تواصل مع الأستاذ عبر الواتساب." },
    { question: "واش الدروس ديال الأكاديمية كافية باش نجتاز الامتحان الوطني؟", answer: " نعم، الدروس اللي كنقدمها مركزة على الحاجيات الأساسية ديال الامتحان الوطني. كل درس كيقدم ليك المعلومات الضرورية اللي غادي تلقاها في الامتحان، سواء من حيث القواعد، المفردات، ولا طرق الكتابة. " },
    // { question: "What is a component?", answer: "A component is a reusable piece of UI in React." },
    // Add more FAQs as needed
  ];

  const [selectedFaq, setSelectedFaq] = useState(null);

  const toggleFaq = (index) => {
    setSelectedFaq(selectedFaq === index ? null : index);
  };

  return (
    <div className="faq-container">
      <h1>Frequently Asked Questions</h1>

      <div className="faq">
      <div className="faq__right">
        <img src={faq} alt="" />
      </div>
        <div className="faq__left">
        <div className="faq-list">
        {faqs.map((faq, index) => (
          <div key={index} className={`faq-item ${selectedFaq === index ? 'active' : ''}`}>
            <button className="faq-question" onClick={() => toggleFaq(index)}>
              <span dir='rtl'>{faq.question}</span>
              <span className="faq-icon">{selectedFaq === index ? '-' : '+'}</span>
            </button>
            <div dir='rtl' className={`faq-answer ${selectedFaq === index ? 'show' : ''}`}>
              {faq.answer}
            </div>
          </div>
        ))}
      </div>
      </div>
      
      </div>
      
      
    </div>
  );
};

export default Faq;
