import React, { useEffect } from "react";
import logo from "./hassan-documents/bluelogo.png";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import "./CourseTenses.css";

import pdfUrls from "./hassan-documents/Present simple summary.pdf";
import CourseVocabularyVideo from "./ACADEMYVIDEOS/vocabulary/gifts of youth 2026 last edit.mp4";
function CourseVocabulary() {
  const onButtonClick = () => {
    const pdfUrl = pdfUrls;
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.download = "document.pdf"; // specify the filename
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  useEffect(() => {
    // Scroll to the top of the page
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    const header = document.getElementById("free__header");
    const headerLogo = document.querySelector("#free__header img");

    if (window.scrollY > 50) {
      // Change 30 to your desired scroll distance

      headerLogo.style.width = "60px";
      header.style.padding = "10px 100px";
      header.style.transition = "background 0.3s ease-in-out";
    } else {
      if (window.innerWidth < "668") {
        headerLogo.style.width = "60px";
        header.style.background = "white";
        header.style.padding = "20px 100px";
      } else {
        headerLogo.style.width = "60px";
        header.style.background = "white";
      }
    }
  }, []);
  window.onscroll = function () {
    const header = document.getElementById("free__header");
    const headerLogo = document.querySelector("#free__header img");

    if (window.scrollY > 50) {
      // Change 30 to your desired scroll distance

      headerLogo.style.width = "60px";
      header.style.padding = "10px 100px";
      header.style.transition = "background 0.3s ease-in-out";
    } else {
      if (window.innerWidth < "668") {
        headerLogo.style.width = "60px";
        header.style.background = "white";
        header.style.padding = "20px 100px";
      } else {
        headerLogo.style.width = "60px";
        header.style.background = "white";
      }
    }
  };
  function scrollToTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  return (
    <div className="CourseVocabulary">
      <header id="free__header">
        {" "}
        <a href="https://toustousacademy.com/">
          <img src={logo} alt="" />
        </a>
        <ul>
          <a href="/" onClick={scrollToTop}>
            <li>Home</li>
          </a>
          <a href="/#about">
            <li>About</li>
          </a>
          <a href="/#feedback">
            <li>Feedback</li>
          </a>
        </ul>
      </header>

      <div className="tenses__page">
        <h2 className="tenses__title">Gifts of youth - مواهب الشباب</h2>

        <div className="video__sections" id="video__sections__id">
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseVocabularyVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Gifts of youth - مواهب الشباب</div>
          </div>
        </div>

        {/* present Continuous */}
        <h2 className="tenses__title"> Humor - الضحك</h2>

        <div className="video__sections" id="video__sections__id">
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseVocabularyVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Humor - الضحك</div>
          </div>
        </div>

        {/* present perfect */}
        <h2 className="tenses__title">Education - التعليم</h2>

        <div className="video__sections" id="video__sections__id">
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseVocabularyVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Education - التعليم</div>
          </div>
        </div>

        {/* <object data={pdfUrls} type=""></object> */}

        <h2 className="tenses__title">
          Sustainable development - التنمية المستدامة
        </h2>

        <div className="video__sections" id="video__sections__id">
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseVocabularyVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Sustainable development - التنمية المستدامة</div>
          </div>
        </div>

        {/* Past Continuous */}
        <h2 className="tenses__title"> Women and power - المرأة والسلطة</h2>

        <div className="video__sections" id="video__sections__id">
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseVocabularyVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Women and power - المرأة والسلطة</div>
          </div>
        </div>

        {/* Past perfect */}
        <h2 className="tenses__title">
          Cultural values and issues - القيم والقضايا الثقافية
        </h2>

        <div className="video__sections" id="video__sections__id">
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseVocabularyVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Cultural values and issues - القيم والقضايا الثقافية</div>
          </div>
        </div>

        {/* <object data={pdfUrls} type=""></object> */}

        <h2 className="tenses__title">Citizenship - المواطنة</h2>

        <div className="video__sections" id="video__sections__id">
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseVocabularyVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Citizenship - المواطنة</div>
          </div>
        </div>

        {/* Future Continuous */}
        <h2 className="tenses__title">
          {" "}
          International organization - المنظمات الدولية
        </h2>

        <div className="video__sections" id="video__sections__id">
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseVocabularyVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>International organization - المنظمات الدولية</div>
          </div>
        </div>

        {/* Future perfect */}
        <h2 className="tenses__title">
          Science and technology - العلم والتكنولوجيا
        </h2>

        <div className="video__sections" id="video__sections__id">
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseVocabularyVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Science and technology - العلم والتكنولوجيا</div>
          </div>
        </div>

        {/* <object data={pdfUrls} type=""></object> */}
        <h2 className="tenses__title">Brain drain - هجرة الأدمغة</h2>

        <div className="video__sections" id="video__sections__id">
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseVocabularyVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Brain drain - هجرة الأدمغة</div>
          </div>
        </div>

        {/* <object data={pdfUrls} type=""></object> */}
      </div>

      {/* past */}
    </div>
  );
}

export default CourseVocabulary;
