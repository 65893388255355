// src/BookSlide.js
import React, { useState, useEffect } from "react";

import logo from "./hassan-documents/bluelogo.png";
import "./BookSlide.css"; // Import CSS for styling
import img1 from "./book__images/My book color 2026 merged_page-0001.jpg";
import img2 from "./book__images/My book color 2026 merged_page-0002.jpg";
import img3 from "./book__images/My book color 2026 merged_page-0003.jpg";
import img4 from "./book__images/My book color 2026 merged_page-0004.jpg";
import img5 from "./book__images/My book color 2026 merged_page-0005.jpg";
import img6 from "./book__images/My book color 2026 merged_page-0006.jpg";
import img7 from "./book__images/My book color 2026 merged_page-0007.jpg";
import img8 from "./book__images/My book color 2026 merged_page-0008.jpg";
import img9 from "./book__images/My book color 2026 merged_page-0009.jpg";
import img10 from "./book__images/My book color 2026 merged_page-0010.jpg";
import img11 from "./book__images/My book color 2026 merged_page-0011.jpg";
import img12 from "./book__images/My book color 2026 merged_page-0012.jpg";
import img13 from "./book__images/My book color 2026 merged_page-0013.jpg";
import img14 from "./book__images/My book color 2026 merged_page-0014.jpg";
import img15 from "./book__images/My book color 2026 merged_page-0015.jpg";
import img16 from "./book__images/My book color 2026 merged_page-0016.jpg";
import img17 from "./book__images/My book color 2026 merged_page-0017.jpg";
import img18 from "./book__images/My book color 2026 merged_page-0018.jpg";
import img19 from "./book__images/My book color 2026 merged_page-0019.jpg";
import img20 from "./book__images/My book color 2026 merged_page-0020.jpg";
import img21 from "./book__images/My book color 2026 merged_page-0021.jpg";
import img22 from "./book__images/My book color 2026 merged_page-0022.jpg";
import img23 from "./book__images/My book color 2026 merged_page-0023.jpg";
import img24 from "./book__images/My book color 2026 merged_page-0024.jpg";
import img25 from "./book__images/My book color 2026 merged_page-0025.jpg";
import img26 from "./book__images/My book color 2026 merged_page-0026.jpg";
import img27 from "./book__images/My book color 2026 merged_page-0027.jpg";
import img28 from "./book__images/My book color 2026 merged_page-0028.jpg";
import img29 from "./book__images/My book color 2026 merged_page-0029.jpg";
import img30 from "./book__images/My book color 2026 merged_page-0030.jpg";
import img31 from "./book__images/My book color 2026 merged_page-0031.jpg";
import img32 from "./book__images/My book color 2026 merged_page-0032.jpg";
import img33 from "./book__images/My book color 2026 merged_page-0033.jpg";
import img34 from "./book__images/My book color 2026 merged_page-0034.jpg";
import img35 from "./book__images/My book color 2026 merged_page-0035.jpg";
import img36 from "./book__images/My book color 2026 merged_page-0036.jpg";
import img37 from "./book__images/My book color 2026 merged_page-0037.jpg";
import img38 from "./book__images/My book color 2026 merged_page-0038.jpg";
import img39 from "./book__images/My book color 2026 merged_page-0039.jpg";
import img40 from "./book__images/My book color 2026 merged_page-0040.jpg";
import img41 from "./book__images/My book color 2026 merged_page-0041.jpg";
import img42 from "./book__images/My book color 2026 merged_page-0042.jpg";
import img43 from "./book__images/My book color 2026 merged_page-0043.jpg";
import img44 from "./book__images/My book color 2026 merged_page-0044.jpg";
import img45 from "./book__images/My book color 2026 merged_page-0045.jpg";
import img46 from "./book__images/My book color 2026 merged_page-0046.jpeg";
import img47 from "./book__images/My book color 2026 merged_page-0047.jpg";
import img48 from "./book__images/My book color 2026 merged_page-0048.jpg";
import img49 from "./book__images/My book color 2026 merged_page-0049.jpg";
import img50 from "./book__images/My book color 2026 merged_page-0050.jpg";
import img51 from "./book__images/My book color 2026 merged_page-0051.jpg";
import img52 from "./book__images/My book color 2026 merged_page-0052.jpg";
import img53 from "./book__images/My book color 2026 merged_page-0053.jpg";
import img54 from "./book__images/My book color 2026 merged_page-0054.jpg";
import img55 from "./book__images/My book color 2026 merged_page-0055.jpg";
import img56 from "./book__images/My book color 2026 merged_page-0056.jpg";
import img57 from "./book__images/My book color 2026 merged_page-0057.jpg";
import img58 from "./book__images/My book color 2026 merged_page-0058.jpg";
import img59 from "./book__images/My book color 2026 merged_page-0059.jpg";
import img60 from "./book__images/My book color 2026 merged_page-0060.jpg";
import img61 from "./book__images/My book color 2026 merged_page-0061.jpg";
import img62 from "./book__images/My book color 2026 merged_page-0062.jpg";
import img63 from "./book__images/My book color 2026 merged_page-0063.jpg";
import img64 from "./book__images/My book color 2026 merged_page-0064.jpg";
import img65 from "./book__images/My book color 2026 merged_page-0065.jpg";
import img66 from "./book__images/My book color 2026 merged_page-0066.jpg";
import img67 from "./book__images/My book color 2026 merged_page-0067.jpg";
import img68 from "./book__images/My book color 2026 merged_page-0068.jpg";
import img69 from "./book__images/My book color 2026 merged_page-0069.jpg";
import img70 from "./book__images/My book color 2026 merged_page-0070.jpg";
import img71 from "./book__images/My book color 2026 merged_page-0071.jpg";
import img72 from "./book__images/My book color 2026 merged_page-0072.jpg";
import img73 from "./book__images/My book color 2026 merged_page-0073.jpg";
import img74 from "./book__images/My book color 2026 merged_page-0074.jpg";
import img75 from "./book__images/My book color 2026 merged_page-0075.jpg";
import img76 from "./book__images/My book color 2026 merged_page-0075.jpg";
import img77 from "./book__images/My book color 2026 merged_page-0077.jpg";
import img78 from "./book__images/My book color 2026 merged_page-0078.jpg";
import img79 from "./book__images/My book color 2026 merged_page-0079.jpg";
import img80 from "./book__images/My book color 2026 merged_page-0080.jpg";
import img81 from "./book__images/My book color 2026 merged_page-0081.jpg";
import img82 from "./book__images/My book color 2026 merged_page-0082.jpg";
import img83 from "./book__images/My book color 2026 merged_page-0083.jpg";
import img84 from "./book__images/My book color 2026 merged_page-0084.jpg";
import img85 from "./book__images/My book color 2026 merged_page-0085.jpg";
import img86 from "./book__images/My book color 2026 merged_page-0085.jpg";
import img87 from "./book__images/My book color 2026 merged_page-0087.jpg";
import img88 from "./book__images/My book color 2026 merged_page-0088.jpg";
import img89 from "./book__images/My book color 2026 merged_page-0089.jpg";
import img90 from "./book__images/My book color 2026 merged_page-0090.jpg";
import img91 from "./book__images/My book color 2026 merged_page-0091.jpg";
import img92 from "./book__images/My book color 2026 merged_page-0092.jpg";
import img93 from "./book__images/My book color 2026 merged_page-0093.jpg";
import img94 from "./book__images/My book color 2026 merged_page-0094.jpg";
import img95 from "./book__images/My book color 2026 merged_page-0095.jpg";
import img96 from "./book__images/My book color 2026 merged_page-0096.jpg";
import img97 from "./book__images/My book color 2026 merged_page-0097.jpg";
import img98 from "./book__images/My book color 2026 merged_page-0098.jpg";
import img99 from "./book__images/My book color 2026 merged_page-0099.jpg";
import img100 from "./book__images/My book color 2026 merged_page-0100.jpg";
import img101 from "./book__images/My book color 2026 merged_page-0101.jpg";
import img102 from "./book__images/My book color 2026 merged_page-0102.jpg";
import img103 from "./book__images/My book color 2026 merged_page-0103.jpg";
import img104 from "./book__images/My book color 2026 merged_page-0104.jpg";
import img105 from "./book__images/My book color 2026 merged_page-0105.jpg";
import img106 from "./book__images/My book color 2026 merged_page-0106.jpg";
import img107 from "./book__images/My book color 2026 merged_page-0107.jpg";
import img108 from "./book__images/My book color 2026 merged_page-0108.jpg";
import img109 from "./book__images/My book color 2026 merged_page-0109.jpg";
import img110 from "./book__images/My book color 2026 merged_page-0110.jpg";
import img111 from "./book__images/My book color 2026 merged_page-0111.jpg";
import img112 from "./book__images/My book color 2026 merged_page-0112.jpg";
import img113 from "./book__images/My book color 2026 merged_page-0113.jpg";
import img114 from "./book__images/My book color 2026 merged_page-0114.jpg";
import img115 from "./book__images/My book color 2026 merged_page-0115.jpg";
import img116 from "./book__images/My book color 2026 merged_page-0116.jpg";
import img117 from "./book__images/My book color 2026 merged_page-0117.jpg";
import img118 from "./book__images/My book color 2026 merged_page-0118.jpg";
import img119 from "./book__images/My book color 2026 merged_page-0119.jpg";
import img120 from "./book__images/My book color 2026 merged_page-0120.jpg";
import img121 from "./book__images/My book color 2026 merged_page-0121.jpg";
import img122 from "./book__images/My book color 2026 merged_page-0122.jpg";
import img123 from "./book__images/My book color 2026 merged_page-0123.jpg";
import img124 from "./book__images/My book color 2026 merged_page-0124.jpg";
import img125 from "./book__images/My book color 2026 merged_page-0125.jpg";
import img126 from "./book__images/My book color 2026 merged_page-0126.jpg";
import img127 from "./book__images/My book color 2026 merged_page-0127.jpg";
import img128 from "./book__images/My book color 2026 merged_page-0128.jpg";
import img129 from "./book__images/My book color 2026 merged_page-0129.jpg";
import img130 from "./book__images/My book color 2026 merged_page-0130.jpg";
import img131 from "./book__images/My book color 2026 merged_page-0131.jpg";

const images = [
  img1,
  img2,
  img3,
  img4,
  img5,
  img6,
  img7,
  img8,
  img9,
  img10,
  img11,
  img12,
  img13,
  img14,
  img15,
  img16,
  img17,
  img18,
  img19,
  img20,
  img21,
  img22,
  img23,
  img24,
  img25,
  img26,
  img27,
  img28,
  img29,
  img30,
  img31,
  img32,
  img33,
  img34,
  img35,
  img36,
  img37,
  img38,
  img39,
  img40,
  img41,
  img42,
  img43,
  img44,
  img45,
  img46,
  img47,
  img48,
  img49,
  img50,
  img51,
  img52,
  img53,
  img54,
  img55,
  img56,
  img57,
  img58,
  img59,
  img60,
  img61,
  img62,
  img63,
  img64,
  img65,
  img66,
  img67,
  img68,
  img69,
  img70,
  img71,
  img72,
  img73,
  img74,
  img75,
  img76,
  img77,
  img78,
  img79,
  img80,
  img81,
  img82,
  img83,
  img84,
  img85,
  img86,
  img87,
  img88,
  img89,
  img90,
  img91,
  img92,
  img93,
  img94,
  img95,
  img96,
  img97,
  img98,
  img99,
  img100,
  img101,
  img102,  
  img103,  
  img104,  
  img105,  
  img106,  
  img107,  
  img108,  
  img109,  
  img110,  
  img111,  
  img112,  
  img113,  
  img114,  
  img115,  
  img116,  
  img117,  
  img118,  
  img119,  
  img120,  
  img121,  
  img122,  
  img123,  
  img124,  
  img125,  
  img126,  
  img127,  
  img128,  
  img129,  
  img130,  
  img131
];

const BookSlide = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevSlide = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length
    );
  };

  useEffect(() => {
    // Scroll to the top of the page
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    const header = document.getElementById("free__header");
    const headerLogo = document.querySelector("#free__header img");

    if (window.scrollY > 50) {
      // Change 30 to your desired scroll distance

      headerLogo.style.width = "60px";
      header.style.padding = "10px 100px";
      header.style.transition = "background 0.3s ease-in-out";
    } else {
      if (window.innerWidth < "668") {
        headerLogo.style.width = "60px";
        header.style.background = "white";
        header.style.padding = "20px 100px";
      } else {
        headerLogo.style.width = "60px";
        header.style.background = "white";
      }
    }
  }, []);
  window.onscroll = function () {
    const header = document.getElementById("free__header");
    const headerLogo = document.querySelector("#free__header img");

    if (window.scrollY > 50) {
      // Change 30 to your desired scroll distance

      headerLogo.style.width = "60px";
      header.style.padding = "10px 100px";
      header.style.transition = "background 0.3s ease-in-out";
    } else {
      if (window.innerWidth < "668") {
        headerLogo.style.width = "60px";
        header.style.background = "white";
        header.style.padding = "20px 100px";
      } else {
        headerLogo.style.width = "60px";
        header.style.background = "white";
      }
    }
  };
  function scrollToTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  const [isScale, setIsScale] = useState(true);

  function handleDoubleClick() {
    if (isScale) {
      document.querySelector(".slider-container").style.transform = "scale(2.5)";
      document.querySelector(".slider-container").style.marginTop = "400px";
      setIsScale(!isScale);
      //   document.querySelector(".slider-image").style.height = "100vh";

      //   document.querySelector(".slider-image").style.position = "absolute";
      // setIsScale(false)
    } else {
      document.querySelector(".slider-container").style.transform = "scale(1)";
      document.querySelector(".slider-container").style.marginTop = "50px";
      setIsScale(!isScale);
      // setIsScale(true)
    }
  }
  const handleContextMenu = (event) => {
    event.preventDefault();
  };

  return (
    <div className="bookPage">
      <header id="free__header">
        {" "}
        <a href="https://toustousacademy.com/">
          <img src={logo} alt="" />
        </a>
        <ul className="book__ul">
          <a href="/" onClick={scrollToTop}>
            <li>Home</li>
          </a>
          <a href="/#about">
            <li>About</li>
          </a>
          <a href="/#feedback">
            <li>Feedback</li>
          </a>
        </ul>
      </header>
      <div className="slider-container">
        <div className="arrow left" onClick={prevSlide}>
          ❰
        </div>
        <img
          onDoubleClick={() => handleDoubleClick()}
          src={images[currentIndex]}
          alt="slider"
          onContextMenu={handleContextMenu}
          className="slider-image"
        />
        <div className="arrow right" onClick={nextSlide}>
          ❱
        </div>
      </div>
      {/* <img className="hhh" src={img0} alt="" /> */}
    </div>
  );
};

export default BookSlide;
