import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import PersonIcon from "@mui/icons-material/Person";
import MailIcon from "@mui/icons-material/Mail";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import LanguageIcon from "@mui/icons-material/Language";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
function Footer() {
  return (
    <div className="footer" id="about">
      <div className="footer__container">
        <div className="footer__left">
          <h2>About Us</h2>
          <p>
            Hello, my name is Khalid Toustous. I am 26 years and I have been
            teaching English for three years old. Additionally, I authored an
            English book specifically designed for baccalaureate students under
            the name " Deep but Short" in order to pass their exams easily and
            effectively.
          </p>
        </div>
        <div className="footer__center">
          <h2>Privacy Policy</h2>
          <Link className="privacy" to="/privacy">
            <div>Privacy Policy</div>
          </Link>
          <Link className="terms" to="/terms">
            <div>Terms and Conditions</div>
          </Link>
        </div>
        <div className="footer__right">
          <h2>Contact us</h2>
          <div className="social__icons">
            <a href="https://www.facebook.com/toustous.khalid" target="_blank">
              <PersonIcon className="footer__icon" />
            </a>
            <a href="mailto:khalidtoustous1@gmail.com" target="_blank">
              <MailIcon className="footer__icon" />
            </a>
            <a href="https://wa.me/212718252186" target="_blank">
              <WhatsAppIcon className="footer__icon" />
            </a>
          </div>
          <h2>Follow us</h2>
          <div className="social__icons">
            <a href="https://www.englishwithtoustous.com" target="_blank">
              <LanguageIcon className="footer__icon" />
            </a>
            <a
              href="https://www.instagram.com/reel/C8RiwzmITUC/?igsh=OGo5MTZiMGV3bmJo"
              target="_blank"
            >
              <InstagramIcon className="footer__icon" />
            </a>
            <a
              href="https://youtube.com/@englishwithtoustous1?si=OyCrOWW0G7DyEa-A"
              target="_blank"
            >
              <YouTubeIcon className="footer__icon" />
            </a>
            <a
              href="https://www.facebook.com/Toustousacademy?mibextid=ZbWKwL"
              target="_blank"
            >
              <FacebookIcon className="footer__icon" />
            </a>
            <a
              href="https://www.linkedin.com/in/khalid-toustous?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
              target="_blank"
            >
              <LinkedInIcon className="footer__icon" />
            </a>
          </div>
          <p className="copyright">&copy; 2024 all rights reserved</p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
