import React, { useState } from "react";
import "./Feedback.css";
import b1 from "./ACADEMYVIDEOS/REVIEWS/book-review/B1.jpg";

import b2 from "./ACADEMYVIDEOS/REVIEWS/book-review/B2.jpg";
import b3 from "./ACADEMYVIDEOS/REVIEWS/book-review/B3.jpg";
import b4 from "./ACADEMYVIDEOS/REVIEWS/book-review/B4.jpg";
import b5 from "./ACADEMYVIDEOS/REVIEWS/book-review/B5.jpg";
import b6 from "./ACADEMYVIDEOS/REVIEWS/book-review/B6.jpg";
import b7 from "./ACADEMYVIDEOS/REVIEWS/book-review/B7.jpg";
import b8 from "./ACADEMYVIDEOS/REVIEWS/book-review/B8.jpg";
import b9 from "./ACADEMYVIDEOS/REVIEWS/book-review/B9.jpg";
import b10 from "./ACADEMYVIDEOS/REVIEWS/book-review/B10.jpg";
import b11 from "./ACADEMYVIDEOS/REVIEWS/book-review/B11.jpg";
import b12 from "./ACADEMYVIDEOS/REVIEWS/book-review/B12.jpg";
import b13 from "./ACADEMYVIDEOS/REVIEWS/book-review/B13.jpg";
import b14 from "./ACADEMYVIDEOS/REVIEWS/book-review/B14.jpg";
import b15 from "./ACADEMYVIDEOS/REVIEWS/book-review/B15.jpg";




import c1 from "./ACADEMYVIDEOS/REVIEWS/course-review/C1.jpg";
import c2 from "./ACADEMYVIDEOS/REVIEWS/course-review/C2.jpg";
import c3 from "./ACADEMYVIDEOS/REVIEWS/course-review/C3.jpg";
import c4 from "./ACADEMYVIDEOS/REVIEWS/course-review/C4.jpg";
import c5 from "./ACADEMYVIDEOS/REVIEWS/course-review/C5.jpg";
import c6 from "./ACADEMYVIDEOS/REVIEWS/course-review/C6.jpg";
import c7 from "./ACADEMYVIDEOS/REVIEWS/course-review/C7.jpg";
import c8 from "./ACADEMYVIDEOS/REVIEWS/course-review/C8.jpg";
import c9 from "./ACADEMYVIDEOS/REVIEWS/course-review/C9.jpg";
import c10 from "./ACADEMYVIDEOS/REVIEWS/course-review/C10.jpg";

import feedbackPic from "./document/feedback.png";

import StarIcon from "@mui/icons-material/Star";
import ChangeHistoryIcon from "@mui/icons-material/ChangeHistory";
import ChangeHistoryTwoToneIcon from "@mui/icons-material/ChangeHistoryTwoTone";

import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

function Feedback() {
  const [bookComment, setBookComment] = useState(0);
  const [courseComment, setCourseComment] = useState(0);
  const courseComments  = [b1, b2, b3, b4, b5, b6, b7, b8,b9, b10, b11, b12, b13, b14, b15];
  const bookComments = [c1, c2, c3, c4, c5, c6, c7, c8, c9,c10];

  const handleBookPrev = () => {
    if (bookComment > 0) {
      setBookComment(bookComment - 1);
    } else {
      setBookComment(bookComments.length - 1);
    }
  };

  const handleBookNext = () => {
    if (bookComment < bookComments.length - 1) {
      setBookComment(bookComment + 1);
    } else {
      setBookComment(0);
    }
  };

  const handleCoursePrev = () => {
    if (courseComment > 0) {
      setCourseComment(courseComment - 1);
    } else {
      setCourseComment(courseComments.length - 1);
    }
  };

  const handleCourseNext = () => {
    if (courseComment < courseComments.length - 1) {
      setCourseComment(courseComment + 1);
    } else {
      setCourseComment(0);
    }
  };

  return (
    <div className="feedback" id="feedback">
      {/* <img src={feedbackPic} alt="" className="feedbackImg" /> */}
      <h2 className="feedbackTitle">Students' feedback</h2>
      <div className="book__course">
        <div className="book__container">
          <h2>About The Course</h2>
          <div className="feedbackBook">
            <ArrowBackIosIcon
              className="backArrow"
              onClick={() => handleBookPrev()}
            />

            <img src={bookComments[bookComment]} alt="" />

            <ArrowForwardIosIcon
              className="nextArrow"
              onClick={() => handleBookNext()}
            />
          </div>
        </div>
        <div className="course__container">
          <h2>About The Book</h2>
          <div className="feedbackCourse">
            <ArrowBackIosIcon
              className="backArrow"
              onClick={() => handleCoursePrev()}
            />

            <img src={courseComments[courseComment]} alt="" />

            <ArrowForwardIosIcon
              className="nextArrow"
              onClick={() => handleCourseNext()}
            />
          </div>
        </div>
      </div>
      <iframe
        className="feedback__video"
        src="https://www.youtube.com/embed/zr24DDbd76A?si=tSds6AAaLvlHICUJ"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerpolicy="strict-origin-when-cross-origin"
        allowfullscreen
      ></iframe>
    </div>
  );
}

export default Feedback;
