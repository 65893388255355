import React, { useEffect, useState } from "react";
import "./Book.css";
import Header from "./Header";
import Footer from "./Footer";
import bookPage from "./hassan-documents/PAGE.jpg";
import logo from "./hassan-documents/bluelogo.png";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import b1 from "./document/B1.jpg";
import b2 from "./document/B2.jpg";
import b3 from "./document/B3.jpg";
import b4 from "./document/B4.jpg";
import b5 from "./document/B5.jpg";
import b6 from "./document/B6.jpg";
import b7 from "./document/B7.jpg";
import b8 from "./document/B8.jpg";

import c1 from "./document/C1.jpg";
import c2 from "./document/C2.jpg";
import c3 from "./document/C3.jpg";
import c4 from "./document/C4.jpg";
import c5 from "./document/C5.jpg";
import c6 from "./document/C6.jpg";
import c7 from "./document/C7.jpg";
import c8 from "./document/C8.jpg";
import c9 from "./document/C9.jpg";
import BookHeader from "./BookHeader";
import mypdf from "./document/website-pdf.pdf";
function Book() {
  const [bookComment, setBookComment] = useState(0);
  const [courseComment, setCourseComment] = useState(0);
  const bookComments = [bookPage, b2, b3, b4, b5, b6, b7, b8];
  const courseComments = [c1, c2, c3, c4, c5, c6, c7, c8, c9];

  const handleBookPrev = () => {
    if (bookComment > 0) {
      setBookComment(bookComment - 1);
    } else {
      setBookComment(bookComments.length - 1);
    }
  };

  const handleBookNext = () => {
    if (bookComment < bookComments.length - 1) {
      setBookComment(bookComment + 1);
    } else {
      setBookComment(0);
    }
  };
  let scaleValue = 1;
  const handleScale = () => {
    scaleValue += 0.1;

    if (scaleValue < 1.5) {
      document.querySelector(
        ".bookImg"
      ).style.transform = `scale(${scaleValue})`;
    } else {
      document.querySelector(".bookImg").style.transform = `scale(1)`;
      scaleValue = 1;
    }
  };
  // document.querySelector(".scaleBtn").addEventListener("click", function(){
  //

  // })

  useEffect(() => {
    // Scroll to the top of the page
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    const header = document.getElementById("free__header");
    const headerLogo = document.querySelector("#free__header img");

    if (window.scrollY > 50) {
      // Change 30 to your desired scroll distance

      headerLogo.style.width = "60px";
      header.style.padding = "10px 100px";
      header.style.transition = "background 0.3s ease-in-out";
    } else {
      if (window.innerWidth < "668") {
        headerLogo.style.width = "60px";
        header.style.background = "white";
        header.style.padding = "20px 100px";
      } else {
        headerLogo.style.width = "60px";
        header.style.background = "white";
      }
    }
  }, []);
  window.onscroll = function () {
    const header = document.getElementById("free__header");
    const headerLogo = document.querySelector("#free__header img");

    if (window.scrollY > 50) {
      // Change 30 to your desired scroll distance

      headerLogo.style.width = "60px";
      header.style.padding = "10px 100px";
      header.style.transition = "background 0.3s ease-in-out";
    } else {
      if (window.innerWidth < "668") {
        headerLogo.style.width = "60px";
        header.style.background = "white";
        header.style.padding = "20px 100px";
      } else {
        headerLogo.style.width = "60px";
        header.style.background = "white";
      }
    }
  };
  function scrollToTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  return (
    <div className="book">
      {/* <BookHeader /> */}
      <header id="free__header">
        <a href="https://toustousacademy.com/">
          <img src={logo} alt="" />
        </a>

        <ul>
          <a href="https://toustousacademy.com/" onClick={scrollToTop}>
            <li>Home</li>
          </a>
          <a href="/#about">
            <li>About</li>
          </a>
          <a href="/#feedback">
            <li>Feedback</li>
          </a>
        </ul>
      </header>

      {/* <Document file={pdfUrl}>
          <Page pageNumber={1} />
        </Document> */}
      <div className="bookPdf__container">
        <iframe
          className="bookPdf"
          src={mypdf}
          style={{ border: "none" }}
          title="PDF Viewer"
        />
      </div>

      {/* <Footer /> */}
    </div>
  );
}

export default Book;
