import React, { useEffect } from "react";
import "./Privacy.css";
import Header from "./Header";
import logo from "./hassan-documents/bluelogo.png";
import Footer from "./Footer";

function Privacy() {
  useEffect(() => {
    // Scroll to the top of the page
    window.scrollTo(0, 0);
  }, []);

  window.onscroll = function () {
    const header = document.getElementById("All__courses__header");
    const headerLogo = document.querySelector("#All__courses__header img");

    if (window.scrollY > 50) {
      // Change 30 to your desired scroll distance

      headerLogo.style.width = "60px";
      header.style.padding = "10px 100px";
      header.style.transition = "background 0.3s ease-in-out";
    } else {
      if (window.innerWidth < "668") {
        headerLogo.style.width = "60px";
        header.style.background = "white";
        header.style.padding = "20px 100px";
      } else {
        headerLogo.style.width = "60px";
        header.style.background = "white";
      }
    }
  };
  useEffect(() => {
    const header = document.getElementById("All__courses__header");
    const headerLogo = document.querySelector("#All__courses__header img");
    headerLogo.style.width = "60px";
    header.style.background = "white";
    header.style.padding = "20px 100px";
  }, []);
  function scrollToTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  return (
    <div>
      <header id="All__courses__header">
        <a href="https://toustousacademy.com/">
          <img src={logo} alt="" />
        </a>
        <ul>
          <a href="/" onClick={scrollToTop}>
            <li>Home</li>
          </a>
          <a href="/#about">
            <li>About</li>
          </a>
          <a href="/#feedback">
            <li>Feedback</li>
          </a>
        </ul>
      </header>
      <div className="privacy__content">
        <h1 class="title-class">Toustous Academy Privacy Policy</h1>
        <p class="effective-date-class">
          <strong>Effective Date:</strong> Sunday, September 2024
        </p>
        <main class="main-class">
          <section class="section-class">
            <p class="content-class">
              At Toustous Academy, accessible from www.toustousacademy.com, we
              respect your privacy and are committed to maintaining your trust.
              We do not collect, store, or share any personal information from
              visitors or users of our website.
            </p>
          </section>

          <section class="section-class">
            <h2 class="subheading-class">No Data Collection</h2>
            <p class="content-class">
              Toustous Academy does not collect personal data such as names,
              email addresses, or any other personally identifiable information.
              We provide educational resources without requiring any user
              information or tracking.
            </p>
          </section>

          <section class="section-class">
            <h2 class="subheading-class">Cookies</h2>
            <p class="content-class">
              Our website does not use cookies or any other tracking
              technologies. You can browse and use all content on the website
              anonymously.
            </p>
          </section>

          <section class="section-class">
            <h2 class="subheading-class">Third-Party Services</h2>
            <p class="content-class">
              Toustous Academy does not partner with or share data with
              third-party services, as we do not collect any data from our
              users.
            </p>
          </section>

          <section class="section-class">
            <h2 class="subheading-class">Contact Us</h2>
            <p class="content-class">
              If you have any questions about our Privacy Policy, you can
              contact us at:{" "}
              <a
                href="mailto:khalidtoustous1@gmail.com"
                class="email-link-class"
              >
                khalidtoustous1@gmail.com
              </a>
            </p>
          </section>
        </main>
      </div>
      <Footer />
    </div>
  );
}

export default Privacy;
