import React, { useState } from "react";
import "./Courses.css";
import toustous from "./toustous.jpg";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import PlayLessonIcon from "@mui/icons-material/PlayLesson";
import PersonIcon from "@mui/icons-material/Person";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { Link } from "react-router-dom";
import LockIcon from "@mui/icons-material/Lock";
import tenses from "./ma7awir/tenses.jpg";
import functions from "./ma7awir/functions.jpg";
import grammar from "./ACADEMYVIDEOS/grammar/grammars.jpg";
import vocabulary from "./ma7awir/VOCABULARY.jpg";
import writing from "./ma7awir/WRITING.jpg";
import startFreeThumbnail from "./startFreeThumbnail.jpeg";
import ENGLISHTENSES2 from "./motivation/ENGLISHTENSES2.jpg";
import WRITING2 from "./motivation/WRITING2.jpg";
import nationalexams from "./motivation/nationalexams.jpg";
import VOCABULARY from "./motivation/VOCABULARY.jpg";
import book from "./book.png";
import book1 from "./book1.jpeg";
import book2 from "./book2.jpeg";
import book3 from "./book3.jpeg";

function Courses() {
  const [containerId, setContainerId] = useState("");

  const handleClick = (event) => {
    const tenses = event.target.closest("#tenses");
    const grammar = event.target.closest("#grammar");
    const vocabulary = event.target.closest("#vocabulary");
    const writing = event.target.closest("#writing");
    const functions = event.target.closest("#functions");
    const national__exams = event.target.closest("#national__exams");

    if (tenses) {
      setContainerId(tenses.id);
      console.log(tenses.id);
      localStorage.setItem("course__title", tenses.id);

      // Outputs the ID of the container
    }
    if (grammar) {
      setContainerId(grammar.id);
      console.log(grammar.id);
      localStorage.setItem("course__title", grammar.id);

      // Outputs the ID of the container
    }
    if (vocabulary) {
      setContainerId(vocabulary.id);
      console.log(vocabulary.id);
      localStorage.setItem("course__title", vocabulary.id);
    }
    if (writing) {
      setContainerId(writing.id);
      console.log(writing.id);
      localStorage.setItem("course__title", writing.id);
    }
    if (functions) {
      setContainerId(functions.id);
      console.log(functions.id);
      localStorage.setItem("course__title", functions.id);
    }
    if (national__exams) {
      setContainerId(national__exams.id);
      console.log(national__exams.id);
      localStorage.setItem("course__title", national__exams.id);
    }

  };
  return (
    <div className="courses" id="courses">
      <h1>Discover our program</h1>
      {/* <div className="categories">
        <h3>Popular Courses</h3>
        <ul>
          <li>All courses</li>

          <li>Functions</li>
          <li>Grammar</li>
          <li>Vocabulary</li>
          <li>Tenses</li>
          <select name="" id="">
            <option value="">More</option>
            <option value="">Writing</option>
            <option value="">Phrasal Verbs</option>
          </select>
        </ul>
      </div> */}

      <div className="courses__list">
        <div className="course">
          {/* <LockIcon className="lockIcon"/> */}
          <img src={startFreeThumbnail} alt="" className="startFreeImg" />
          <div className="course__title freeTrialTitle">
            Unit 1 : Gifts Of Youth
          </div>
          <div className="icons__labels">
            <div className="icon__label">
              <PlayLessonIcon className="icon" />
              <span>10 Lessons</span>
            </div>
            <div className="icon__label">
              <PersonIcon className="icon" />
              <span> +35 students</span>
            </div>
            <div className="icon__label">
              <AccessTimeIcon className="icon" />
              <span>1 h 30 min</span>
            </div>
          </div>
          <div className="button__whatsapp">
            <Link to="/AllCourses">
              <button className="freeAccess">Start Free</button>
            </Link>
            <a href="https://wa.me/212718252186" target="_blank">
              <WhatsAppIcon className="whatsappIcon" />
            </a>
          </div>
        </div>
        <div className="course" id="tenses">
          <img src={ENGLISHTENSES2} alt="" />
          <div className="course__title__lock">
            <span className="course__title">Tenses Lessons</span>
            <LockIcon className="lockIcon" />
          </div>
          <div className="icons__labels">
            <div className="icon__label">
              <PlayLessonIcon className="icon" />
              <span>10 Lessons</span>
            </div>
            <div className="icon__label">
              <PersonIcon className="icon" />
              <span> +25 students</span>
            </div>
            <div className="icon__label">
              <AccessTimeIcon className="icon" />
              <span>2 h 40 min</span>
            </div>
          </div>
          <div className="button__whatsapp">
            <Link to="/login">
              <button onClick={handleClick}>Get Started</button>
            </Link>
            <a href="https://wa.me/212718252186" target="_blank">
              <WhatsAppIcon className="whatsappIcon" />
            </a>
          </div>
        </div>
        <div className="course" id="functions">
          <img src={functions} alt="" />
          <div className="course__title__lock">
            <span className="course__title">Functions Lessons</span>
            <LockIcon className="lockIcon" />
          </div>
          <div className="icons__labels">
            <div className="icon__label">
              <PlayLessonIcon className="icon" />
              <span>11 Lessons</span>
            </div>
            <div className="icon__label">
              <PersonIcon className="icon" />
              <span> +25 students</span>
            </div>
            <div className="icon__label">
              <AccessTimeIcon className="icon" />
              <span>1 h 30 min</span>
            </div>
          </div>
          <div className="button__whatsapp">
            <Link to="/login">
              <button onClick={handleClick}>Get Started</button>
            </Link>
            <a href="https://wa.me/212718252186" target="_blank">
              <WhatsAppIcon className="whatsappIcon" />
            </a>
          </div>
        </div>
        <div className="course" id="grammar">
          <img src={grammar} alt="" />
          <div className="course__title__lock">
            <span className="course__title">Grammar Lessons</span>
            <LockIcon className="lockIcon" />
          </div>
          <div className="icons__labels">
            <div className="icon__label">
              <PlayLessonIcon className="icon" />
              <span>12 Lessons</span>
            </div>
            <div className="icon__label">
              <PersonIcon className="icon" />
              <span> +25 students</span>
            </div>
            <div className="icon__label">
              <AccessTimeIcon className="icon" />
              <span>2 h 30 min</span>
            </div>
          </div>
          <div className="button__whatsapp">
            <Link to="/login">
              <button onClick={handleClick}>Get Started</button>
            </Link>
            <a href="https://wa.me/212718252186" target="_blank">
              <WhatsAppIcon className="whatsappIcon" />
            </a>
          </div>
        </div>
        <div className="course" id="vocabulary">
          <img src={VOCABULARY} alt="" />
          <div className="course__title__lock">
            <span className="course__title">Vocabulary Lessons</span>
            <LockIcon className="lockIcon" />
          </div>
          <div className="icons__labels">
            <div className="icon__label">
              <PlayLessonIcon className="icon" />
              <span>10 Lessons</span>
            </div>
            <div className="icon__label">
              <PersonIcon className="icon" />
              <span> +25 students</span>
            </div>
            <div className="icon__label">
              <AccessTimeIcon className="icon" />
              <span>1 h 40 min</span>
            </div>
          </div>
          <div className="button__whatsapp">
            <Link to="/login">
              <button onClick={handleClick}>Get Started</button>
            </Link>
            <a href="https://wa.me/212718252186" target="_blank">
              <WhatsAppIcon className="whatsappIcon" />
            </a>
          </div>
        </div>
        <div className="course" id="writing">
          <img src={WRITING2} alt="" />
          <div className="course__title__lock">
            <span className="course__title">Writing Lessons</span>
            <LockIcon className="lockIcon" />
          </div>
          <div className="icons__labels">
            <div className="icon__label">
              <PlayLessonIcon className="icon" />
              <span>11 Lessons</span>
            </div>
            <div className="icon__label">
              <PersonIcon className="icon" />
              <span> +25 students</span>
            </div>
            <div className="icon__label">
              <AccessTimeIcon className="icon" />
              <span>2 h 20 min</span>
            </div>
          </div>
          <div className="button__whatsapp">
            <Link to="/login">
              <button onClick={handleClick}>Get Started</button>
            </Link>
            <a href="https://wa.me/212718252186" target="_blank">
              <WhatsAppIcon className="whatsappIcon" />
            </a>
          </div>
        </div>
        <div className="course" id="national__exams">
          {/* <LockIcon className="lockIcon"/> */}
          <img src={nationalexams} alt="" />
          <div className="course__title__lock">
            <span className="course__title">National Exams</span>
            <LockIcon className="lockIcon" />
          </div>
          <div className="icons__labels">
            <div className="icon__label">
              <PlayLessonIcon className="icon" />
              <span>7 Exams</span>
            </div>
            <div className="icon__label">
              <PersonIcon className="icon" />
              <span> +25 students</span>
            </div>
            <div className="icon__label">
              <AccessTimeIcon className="icon" />
              <span>3 h 30 min</span>
            </div>
          </div>
          <div className="button__whatsapp">
            <Link to="/login">
              <button onClick={handleClick}>Get Started</button>
            </Link>
            <a href="https://wa.me/212718252186" target="_blank">
              <WhatsAppIcon className="whatsappIcon" />
            </a>
          </div>
        </div>
        <div className="course">
          <img src={book} alt="" />
          <div className="course__title__lock">
            <span className="course__title">Deep but short book</span>

            <LockIcon className="lockIcon" />
          </div>
          <div className="icons__labels">
            <div className="icon__label">
              <span>Summary + Exercises + National Exams + Solutions</span>
            </div>
          </div>

          {/* <div className="icons__labels">
            <div className="icon__label">
              <PlayLessonIcon className="icon" />
              <span>7 Exams</span>
            </div>
            <div className="icon__label">
              <PersonIcon className="icon" />
              <span> +25 students</span>
            </div>
            <div className="icon__label">
              <AccessTimeIcon className="icon" />
              <span>3 h 30 min</span>
            </div>
          </div> */}
          <div className="button__whatsapp">
            <Link to="/Book/login">
              <button>Get Started</button>
            </Link>
            <a href="https://wa.me/212718252186" target="_blank">
              <WhatsAppIcon className="whatsappIcon" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Courses;
