import React, { useEffect } from "react";
import logo from "./hassan-documents/bluelogo.png";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import "./CourseTenses.css";

import pdfUrls from "./hassan-documents/Present simple summary.pdf";
import CourseFunctionsVideo from "./ACADEMYVIDEOS/vocabulary/gifts of youth 2026 last edit.mp4";
function CourseFunctions() {
  const onButtonClick = () => {
    const pdfUrl = pdfUrls;
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.download = "document.pdf"; // specify the filename
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  useEffect(() => {
    // Scroll to the top of the page
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    const header = document.getElementById("free__header");
    const headerLogo = document.querySelector("#free__header img");

    if (window.scrollY > 50) {
      // Change 30 to your desired scroll distance

      headerLogo.style.width = "60px";
      header.style.padding = "10px 100px";
      header.style.transition = "background 0.3s ease-in-out";
    } else {
      if (window.innerWidth < "668") {
        headerLogo.style.width = "60px";
        header.style.background = "white";
        header.style.padding = "20px 100px";
      } else {
        headerLogo.style.width = "60px";
        header.style.background = "white";
      }
    }
  }, []);
  window.onscroll = function () {
    const header = document.getElementById("free__header");
    const headerLogo = document.querySelector("#free__header img");

    if (window.scrollY > 50) {
      // Change 30 to your desired scroll distance

      headerLogo.style.width = "60px";
      header.style.padding = "10px 100px";
      header.style.transition = "background 0.3s ease-in-out";
    } else {
      if (window.innerWidth < "668") {
        headerLogo.style.width = "60px";
        header.style.background = "white";
        header.style.padding = "20px 100px";
      } else {
        headerLogo.style.width = "60px";
        header.style.background = "white";
      }
    }
  };
  function scrollToTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  return (
    <div className="CourseFunctions">
      <header id="free__header">
        {" "}
        <a href="https://toustousacademy.com/">
          <img src={logo} alt="" />
        </a>
        <ul>
          <a href="/" onClick={scrollToTop}>
            <li>Home</li>
          </a>
          <a href="/#about">
            <li>About</li>
          </a>
          <a href="/#feedback">
            <li>Feedback</li>
          </a>
        </ul>
      </header>

      <div className="tenses__page">
        <h2 className="tenses__title">
          Expressing opinion : agreeing and disagreeing
        </h2>

        <div className="video__sections" id="video__sections__id">
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseFunctionsVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Expressing opinion : agreeing and disagreeing</div>
          </div>
        </div>

        {/* present Continuous */}
        <h2 className="tenses__title"> Asking for advice</h2>

        <div className="video__sections" id="video__sections__id">
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseFunctionsVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Asking for advice</div>
          </div>
        </div>

        {/* present perfect */}
        <h2 className="tenses__title">Asking for clarification</h2>

        <div className="video__sections" id="video__sections__id">
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseFunctionsVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Asking for clarification</div>
          </div>
        </div>

        {/* <object data={pdfUrls} type=""></object> */}

        <h2 className="tenses__title">Complaining and apologising</h2>

        <div className="video__sections" id="video__sections__id">
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseFunctionsVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Complaining and apologizing</div>
          </div>
        </div>

        {/* Past Continuous */}
        <h2 className="tenses__title"> Expressing certainty and uncertainty</h2>

        <div className="video__sections" id="video__sections__id">
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseFunctionsVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Expressing certainty and uncertainty</div>
          </div>
        </div>

        {/* Past perfect */}
        <h2 className="tenses__title">
          Making invitation : accepting and refusing
        </h2>

        <div className="video__sections" id="video__sections__id">
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseFunctionsVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Making invitation : accepting and refusing</div>
          </div>
        </div>

        {/* <object data={pdfUrls} type=""></object> */}

        <h2 className="tenses__title">
          Making request : accepting and refusing
        </h2>

        <div className="video__sections" id="video__sections__id">
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseFunctionsVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Making request : accepting and refusing</div>
          </div>
        </div>

        {/* Future Continuous */}
        <h2 className="tenses__title">
          {" "}
          Making suggestion : accepting and refusing
        </h2>

        <div className="video__sections" id="video__sections__id">
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseFunctionsVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Making suggestion : accepting and refusing</div>
          </div>
        </div>
        <div className="summary__button" onClick={onButtonClick}>
          <span>Summary + Exercises</span>

          <CloudDownloadIcon />
        </div>
        {/* Future perfect */}
        <h2 className="tenses__title">Responding to bad and good news</h2>

        <div className="video__sections" id="video__sections__id">
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseFunctionsVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Responding to bad and good news</div>
          </div>
        </div>
        <div className="summary__button" onClick={onButtonClick}>
          <span>Summary + Exercises</span>

          <CloudDownloadIcon />
        </div>
        {/* <object data={pdfUrls} type=""></object> */}
        <h2 className="tenses__title">Expressions of definition</h2>

        <div className="video__sections" id="video__sections__id">
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseFunctionsVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Expressions of definition</div>
          </div>
        </div>
        <div className="summary__button" onClick={onButtonClick}>
          <span>Summary + Exercises</span>

          <CloudDownloadIcon />
        </div>
        {/* <object data={pdfUrls} type=""></object> */}
        <h2 className="tenses__title">
          Asking for permission : accepting and refusing
        </h2>

        <div className="video__sections" id="video__sections__id">
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseFunctionsVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Asking for permission : accepting and refusing</div>
          </div>
        </div>
        <div className="summary__button" onClick={onButtonClick}>
          <span>Summary + Exercises</span>

          <CloudDownloadIcon />
        </div>
        {/* <object data={pdfUrls} type=""></object> */}
        <h2 className="tenses__title">National exams 1</h2>

        <div className="video__sections" id="video__sections__id">
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseFunctionsVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>National exams 1</div>
          </div>
        </div>
        <div className="summary__button" onClick={onButtonClick}>
          <span>Summary + Exercises</span>

          <CloudDownloadIcon />
        </div>
        {/* <object data={pdfUrls} type=""></object> */}
        <h2 className="tenses__title">National exam : 2</h2>

        <div className="video__sections" id="video__sections__id">
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseFunctionsVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>National exam : 2</div>
          </div>
        </div>
        <div className="summary__button" onClick={onButtonClick}>
          <span>Summary + Exercises</span>

          <CloudDownloadIcon />
        </div>
        {/* <object data={pdfUrls} type=""></object> */}
      </div>

      {/* past */}
    </div>
  );
}

export default CourseFunctions;
