import React, { useEffect } from "react";
import logo from "./hassan-documents/bluelogo.png";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import "./CourseTenses.css";

import pdfUrls from "./hassan-documents/Present simple summary.pdf";
import CourseWritingVideo from "./ACADEMYVIDEOS/vocabulary/gifts of youth 2026 last edit.mp4";
function CourseWriting() {
  const onButtonClick = () => {
    const pdfUrl = pdfUrls;
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.download = "document.pdf"; // specify the filename
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  useEffect(() => {
    // Scroll to the top of the page
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    const header = document.getElementById("free__header");
    const headerLogo = document.querySelector("#free__header img");

    if (window.scrollY > 50) {
      // Change 30 to your desired scroll distance

      headerLogo.style.width = "60px";
      header.style.padding = "10px 100px";
      header.style.transition = "background 0.3s ease-in-out";
    } else {
      if (window.innerWidth < "668") {
        headerLogo.style.width = "60px";
        header.style.background = "white";
        header.style.padding = "20px 100px";
      } else {
        headerLogo.style.width = "60px";
        header.style.background = "white";
      }
    }
  }, []);
  window.onscroll = function () {
    const header = document.getElementById("free__header");
    const headerLogo = document.querySelector("#free__header img");

    if (window.scrollY > 50) {
      // Change 30 to your desired scroll distance

      headerLogo.style.width = "60px";
      header.style.padding = "10px 100px";
      header.style.transition = "background 0.3s ease-in-out";
    } else {
      if (window.innerWidth < "668") {
        headerLogo.style.width = "60px";
        header.style.background = "white";
        header.style.padding = "20px 100px";
      } else {
        headerLogo.style.width = "60px";
        header.style.background = "white";
      }
    }
  };
  function scrollToTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  return (
    <div className="CourseWriting">
      <header id="free__header">
        {" "}
        <a href="https://toustousacademy.com/">
          <img src={logo} alt="" />
        </a>
        <ul>
          <a href="/" onClick={scrollToTop}>
            <li>Home</li>
          </a>
          <a href="/#about">
            <li>About</li>
          </a>
          <a href="/#feedback">
            <li>Feedback</li>
          </a>
        </ul>
      </header>

      <div className="tenses__page">
        <h2 className="tenses__title">Linking words</h2>

        <div className="video__sections">
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseWritingVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Linking words : Addition</div>
          </div>
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseWritingVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Linking words : concession</div>
          </div>
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseWritingVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Linking words : cause and effect</div>
          </div>
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseWritingVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Linking words : Exercises</div>
          </div>
        </div>

        {/* present Continuous */}
        <h2 className="tenses__title"> Paragraph writing</h2>

        <div className="video__sections">
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseWritingVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Paragraph writing - explanation</div>
          </div>
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseWritingVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Paragraph writing - exercises</div>
          </div>
        </div>

        {/* present perfect */}
        <h2 className="tenses__title">Descriptive Paragraph</h2>

        <div className="video__sections">
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseWritingVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Descriptive Paragraph - explanation</div>
          </div>
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseWritingVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Descriptive Paragraph - exercises</div>
          </div>
        </div>

        {/* <object data={pdfUrls} type=""></object> */}

        <h2 className="tenses__title">A funny story</h2>

        <div className="video__sections">
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseWritingVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>A funny story - explanation</div>
          </div>
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseWritingVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>A funny story - exercises</div>
          </div>
        </div>

        {/* Past Continuous */}
        <h2 className="tenses__title"> Writing a report</h2>

        <div className="video__sections">
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseWritingVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Writing a report - explanation</div>
          </div>
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseWritingVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Writing a report - exercises</div>
          </div>
        </div>

        {/* Past perfect */}
        <h2 className="tenses__title">Formal email</h2>

        <div className="video__sections">
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseWritingVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Formal email - explanation</div>
          </div>
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseWritingVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Formal email - exercises</div>
          </div>
        </div>

        {/* <object data={pdfUrls} type=""></object> */}

        <h2 className="tenses__title">Informal email</h2>

        <div className="video__sections">
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseWritingVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Informal email - explanation</div>
          </div>
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseWritingVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Informal email - exercises</div>
          </div>
        </div>

        {/* Future Continuous */}
        <h2 className="tenses__title"> Formal & informal letter</h2>

        <div className="video__sections">
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseWritingVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Formal & informal letter - explanation</div>
          </div>
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseWritingVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Formal & informal letter - exercises</div>
          </div>
        </div>

        {/* Future perfect */}
        <h2 className="tenses__title">Review a film</h2>

        <div className="video__sections">
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseWritingVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Review a film - explanation</div>
          </div>
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseWritingVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Review a film - exercises</div>
          </div>
        </div>

        {/* <object data={pdfUrls} type=""></object> */}

        <h2 className="tenses__title">Review a book</h2>

        <div className="video__sections">
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseWritingVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Review a book - explanation</div>
          </div>
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseWritingVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Review a book - exercises</div>
          </div>
        </div>

        {/* <object data={pdfUrls} type=""></object> */}
        <h2 className="tenses__title">Article : advantage and disadvantages</h2>

        <div className="video__sections">
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseWritingVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Article : advantage and disadvantages - explanation</div>
          </div>
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseWritingVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Article : advantage and disadvantages - exercises</div>
          </div>
        </div>

        {/* <object data={pdfUrls} type=""></object> */}
        <h2 className="tenses__title">Article : causes and effects</h2>

        <div className="video__sections">
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseWritingVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Article : causes and effects - explanation</div>
          </div>
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseWritingVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Article : causes and effects - exercises</div>
          </div>
        </div>

        {/* <object data={pdfUrls} type=""></object> */}
        <h2 className="tenses__title">Argumentative essay</h2>

        <div className="video__sections">
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseWritingVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Argumentative essay - explanation</div>
          </div>
          <div className="video__title">
            {/* <video
              className="freeIntro__iframe"
              src={CourseWritingVideo}
              controlsList="nodownload"
              controls
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
            <div>Argumentative essay - exercises</div>
          </div>
        </div>

        {/* <object data={pdfUrls} type=""></object> */}
      </div>

      {/* past */}
    </div>
  );
}

export default CourseWriting;
