import React, { useEffect } from "react";
import "./Terms.css";
import logo from "./hassan-documents/bluelogo.png";
import Footer from "./Footer";

function Terms() {
  useEffect(() => {
    // Scroll to the top of the page
    window.scrollTo(0, 0);
  }, []);

  window.onscroll = function () {
    const header = document.getElementById("All__courses__header");
    const headerLogo = document.querySelector("#All__courses__header img");

    if (window.scrollY > 50) {
      // Change 30 to your desired scroll distance

      headerLogo.style.width = "60px";
      header.style.padding = "10px 100px";
      header.style.transition = "background 0.3s ease-in-out";
    } else {
      if (window.innerWidth < "668") {
        headerLogo.style.width = "60px";
        header.style.background = "white";
        header.style.padding = "20px 100px";
      } else {
        headerLogo.style.width = "60px";
        header.style.background = "white";
      }
    }
  };
  useEffect(() => {
    const header = document.getElementById("All__courses__header");
    const headerLogo = document.querySelector("#All__courses__header img");
    headerLogo.style.width = "60px";
    header.style.background = "white";
    header.style.padding = "20px 100px";
  }, []);
  function scrollToTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
  return (
    <div>
      <header id="All__courses__header">
        <a href="https://toustousacademy.com/">
          <img src={logo} alt="" />
        </a>
        <ul>
          <a href="/" onClick={scrollToTop}>
            <li>Home</li>
          </a>
          <a href="/#about">
            <li>About</li>
          </a>
          <a href="/#feedback">
            <li>Feedback</li>
          </a>
        </ul>
      </header>
      <div className="privacy__content">
        <h1 class="title-class">Terms and Conditions</h1>
        <p class="effective-date-class">
          <strong>Effective Date:</strong> Sunday, September 2024
        </p>

        <main class="main-class">
          <section class="section-class">
            <p class="content-class">
              Welcome to Toussous Academy! By accessing or using our website at{" "}
              <a href="http://www.toustousacademy.com" class="link-class">
                www.toustousacademy.com
              </a>
              , you agree to be bound by the following Terms and Conditions.
            </p>
          </section>

          <section class="section-class">
            <h2 class="subheading-class">1. Use of Our Website</h2>
            <p class="content-class">
              Toussous Academy is a free educational platform designed to
              provide users with access to learning materials. By using the
              website, you agree to use the content for educational purposes
              only.
            </p>
          </section>

          <section class="section-class">
            <h2 class="subheading-class">2. User Accounts</h2>
            <p class="content-class">
              We do not require or allow the creation of user accounts, and we
              do not collect or store personal information from users.
            </p>
          </section>

          <section class="section-class">
            <h2 class="subheading-class">3. Courses and Services</h2>
            <p class="content-class">
              Toussous Academy offers educational materials at no cost. The
              content provided is for informational purposes only, and we
              reserve the right to change or update the content at any time.
            </p>
          </section>

          <section class="section-class">
            <h2 class="subheading-class">4. Intellectual Property</h2>
            <p class="content-class">
              All content on Toussous Academy, including text, images, and
              videos, is the intellectual property of Toussous Academy or its
              licensors. You may not reproduce, distribute, or use the content
              for commercial purposes without our written permission.
            </p>
          </section>

          <section class="section-class">
            <h2 class="subheading-class">5. Limitation of Liability</h2>
            <p class="content-class">
              Toussous Academy is not responsible for any damages or losses
              resulting from your use of the website. All content is provided
              "as is" without any warranties of accuracy or reliability.
            </p>
          </section>

          <section class="section-class">
            <h2 class="subheading-class">6. Changes to Terms and Conditions</h2>
            <p class="content-class">
              We may modify these Terms and Conditions at any time. Any updates
              will be posted on this page with an updated revision date.
            </p>
          </section>

          <section class="section-class">
            <h2 class="subheading-class">7. Contact Us</h2>
            <p class="content-class">
              If you have any questions about these Terms and Conditions, please
              contact us at:{" "}
              <a
                href="mailto:khalidtoustous1@gmail.com"
                class="email-link-class"
              >
                khalidtoustous1@gmail.com
              </a>
            </p>
          </section>
        </main>
      </div>
      <Footer />
    </div>
  );
}

export default Terms;
