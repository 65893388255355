import React, { useEffect } from "react";
import logo from "./hassan-documents/bluelogo.png";
import "./FreeIntroduction.css";
import tensesIntroVideo from "./HASSAN-EXERCISES/Intro to tenses 2030.mp4";
function TensesIntroduction() {
  useEffect(() => {
    // Scroll to the top of the page
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    const header = document.getElementById("free__header");
    const headerLogo = document.querySelector("#free__header img");

    if (window.scrollY > 50) {
      // Change 30 to your desired scroll distance

      headerLogo.style.width = "60px";
      header.style.padding = "10px 100px";
      header.style.transition = "background 0.3s ease-in-out";
    } else {
      if (window.innerWidth < "668") {
        headerLogo.style.width = "60px";
        header.style.background = "white";
        header.style.padding = "20px 100px";
      } else {
        headerLogo.style.width = "60px";
        header.style.background = "white";
      }
    }
  }, []);
  window.onscroll = function () {
    const header = document.getElementById("free__header");
    const headerLogo = document.querySelector("#free__header img");

    if (window.scrollY > 50) {
      // Change 30 to your desired scroll distance

      headerLogo.style.width = "60px";
      header.style.padding = "10px 100px";
      header.style.transition = "background 0.3s ease-in-out";
    } else {
      if (window.innerWidth < "668") {
        headerLogo.style.width = "60px";
        header.style.background = "white";
        header.style.padding = "20px 100px";
      } else {
        headerLogo.style.width = "60px";
        header.style.background = "white";
      }
    }
  };
  function scrollToTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
  return (
    <div className="TensesIntroduction">
      <header id="free__header">
        {" "}
        <a href="https://toustousacademy.com/">
          <img src={logo} alt="" />
        </a>
        <ul>
          <a href="/" onClick={scrollToTop}>
            <li>Home</li>
          </a>
          <a href="/#about">
            <li>About</li>
          </a>
          <a href="/#feedback">
            <li>Feedback</li>
          </a>
        </ul>
      </header>

      <div className="video__section">
        <video
          className="freeIntro__iframe"
          src={tensesIntroVideo}
          controlsList="nodownload"
          controls
        >
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  );
}

export default TensesIntroduction;
