import React, { useEffect, useState } from "react";

import logo from "./hassan-documents/white-logo.png";
import bluelogo from "./hassan-documents/bluelogo.png";
import { Link } from "react-router-dom";
import "./BookLogin.css";
function BookLogin() {
  useEffect(() => {
    // Fetching data from API
    // fetch('https://jsonplaceholder.typicode.com/posts')
    //  .then(response => response.json())
    //  .then(data => console.log(data))
    //  .catch(error => console.error('Error:', error))
    //  .finally(() => console.log('Fetch complete'))

    localStorage.setItem("username", "toustous");
    localStorage.setItem("password", "123");
  }, []);

  function scrollToTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
  useEffect(() => {
    scrollToTop();
  });

  window.onscroll = function () {
    const header = document.getElementById("bookHeader");
    const headerLogo = document.querySelector("#bookHeader a img");

    if (window.scrollY > 50) {
      // Change 30 to your desired scroll distance

      headerLogo.style.width = "60px";
      header.style.padding = "10px 100px";
      header.style.transition = "background 0.3s ease-in-out";
    } else {
      if (window.innerWidth < "668") {
        headerLogo.style.width = "60px";
        header.style.background = "#253E56";
        header.style.padding = "20px";
      } else {
        headerLogo.style.width = "60px";
        header.style.background = "#253E56";
      }
    }
  };

  useEffect(() => {
    const headerLogo = document.querySelector("#bookHeader a img");
    headerLogo.style.width = "60px";
  }, []);

  
  // Add event listener for resize

  // Initial check on page load

  

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const handleLogin = (e) => {
    e.preventDefault();
    if (
      username === localStorage.getItem("username") &&
      password === localStorage.getItem("password")
    ) {
      // localStorage.setItem("isLoggedIn", true);
      window.location.href = "/book/deep-but-short";
    }
    if (username !== localStorage.getItem("username") && username !== "") {
      document.querySelector(".username").style.display = "block";
      document.querySelector(".username").textContent = "username is incorrect";
    }
    if (password !== localStorage.getItem("password") && password !== "") {
      document.querySelector(".password").style.display = "block";
      document.querySelector(".password").textContent = "password is incorrect";
    }

    if (username === "") {
      document.querySelector(".username").style.display = "block";
      document.querySelector(".username").textContent = "username is required";
    }
    if (password === "") {
      document.querySelector(".password").style.display = "block";
      document.querySelector(".password").textContent = "password is required";
    }

    setUsername("");
    setPassword("");
  };
  return (
    <div className="bookLogin">
      <header id="bookHeader" className="book__header">
        <a href="https://toustousacademy.com/">
          <img src={logo} alt="" />
        </a>
        <ul>
          <a href="https://toustousacademy.com/">
            <li>Home</li>
          </a>

          <a href="/#about">
            <li>About</li>
          </a>
          <a href="/#feedback">
            <li>Feedback</li>
          </a>

          {/* <li>Log in</li> */}
        </ul>
      </header>
      <div className="bookLogin__form">
        <p>
        We're here to help you pass your baccalaureate exam easily and in a
        short time.
        </p>
        {/* <img src={bluelogo} alt="" /> */}
        <input
          type="text"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <p className="username">username is required</p>
        <input
          type="text"
          placeholder="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <p className="password">password is required</p>
        <button onClick={handleLogin}>Login</button>
        <a href="https://wa.me/212718252186" style={{textAlign:"center"}} target="_blank" >forgot password?</a>

      </div>
    </div>
  );
}

export default BookLogin;
