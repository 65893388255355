import React, { useEffect, useState } from "react";
import "./DownloadIrrigularVerbs.css";

import logo from "./hassan-documents/bluelogo.png";
import descPdf from "./HASSAN-EXERCISES/Descriptive paragraph+ exercises.pdf";
import BookHeader from "./BookHeader";
function WritingExercises() {
  const [ex1q1, setex1q1] = useState("");
  const [ex1q2, setex1q2] = useState("");
  const [ex1q3, setex1q3] = useState("");
  const [ex1q4, setex1q4] = useState("");
  const [ex1q5, setex1q5] = useState("");
  const [ex1q6, setex1q6] = useState("");

  // // checkboxes
  const [isChecked1, setIsChecked1] = useState(false);
  const [isChecked2, setIsChecked2] = useState(false);
  const [isChecked3, setIsChecked3] = useState(false);
  //  const [checkbox1,setCheckbox1] = useState(null);
  //  const [checkbox2,setCheckbox2] = useState(null);
  //  const [checkbox3,setCheckbox3] = useState(null);
  useEffect(() => {
    // Scroll to the top of the page
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    const header = document.getElementById("free__header");
    const headerLogo = document.querySelector("#free__header img");

    if (window.scrollY > 50) {
      // Change 30 to your desired scroll distance

      headerLogo.style.width = "60px";
      header.style.padding = "10px 100px";
      header.style.transition = "background 0.3s ease-in-out";
    } else {
      if (window.innerWidth < "668") {
        headerLogo.style.width = "60px";
        header.style.background = "white";
        header.style.padding = "20px 100px";
      } else {
        headerLogo.style.width = "60px";
        header.style.background = "white";
      }
    }
  }, []);
  window.onscroll = function () {
    const header = document.getElementById("free__header");
    const headerLogo = document.querySelector("#free__header img");

    if (window.scrollY > 50) {
      // Change 30 to your desired scroll distance

      headerLogo.style.width = "60px";
      header.style.padding = "10px 100px";
      header.style.transition = "background 0.3s ease-in-out";
    } else {
      if (window.innerWidth < "668") {
        headerLogo.style.width = "60px";
        header.style.background = "white";
        header.style.padding = "20px 100px";
      } else {
        headerLogo.style.width = "60px";
        header.style.background = "white";
      }
    }
  };
  function scrollToTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  // checkEx1
  const checkEx1 = () => {
    if (ex1q1.toLocaleLowerCase() === "confident") {
      document.querySelector(".ex1q1").style.border = "1px solid green";
    } else {
      document.querySelector(".ex1q1").style.border = "1px solid red";
    }
    if (ex1q2.toLocaleLowerCase() === "creativity") {
      document.querySelector(".ex1q2").style.border = "1px solid green";
    } else {
      document.querySelector(".ex1q2").style.border = "1px solid red";
    }
    if (ex1q3.toLocaleLowerCase() === "independence") {
      document.querySelector(".ex1q3").style.border = "1px solid green";
    } else {
      document.querySelector(".ex1q3").style.border = "1px solid red";
    }
    if (ex1q4.toLocaleLowerCase() === "enthusiastic") {
      document.querySelector(".ex1q4").style.border = "1px solid green";
    } else {
      document.querySelector(".ex1q4").style.border = "1px solid red";
    }
    if (ex1q5.toLocaleLowerCase() === "optimistic") {
      document.querySelector(".ex1q5").style.border = "1px solid green";
    } else {
      document.querySelector(".ex1q5").style.border = "1px solid red";
    }
    if (ex1q6.toLocaleLowerCase() === "competence") {
      document.querySelector(".ex1q6").style.border = "1px solid green";
    } else {
      document.querySelector(".ex1q6").style.border = "1px solid red";
    }
    console.log(ex1q1);
    console.log(ex1q2);
    console.log(ex1q3);
    console.log(ex1q4);
    console.log(ex1q5);
    console.log(ex1q6);
  };

  const clearEx1 = () => {
    setex1q1("");
    setex1q2("");
    setex1q3("");
    setex1q4("");
    setex1q5("");
    setex1q6("");
    document.querySelector(".ex1q1").textContent = "";
    document.querySelector(".ex1q2").textContent = "";
    document.querySelector(".ex1q3").textContent = "";
    document.querySelector(".ex1q4").textContent = "";
    document.querySelector(".ex1q5").textContent = "";
    document.querySelector(".ex1q6").textContent = "";
  };
  const showEx1 = () => {
    document.querySelector(".ex1q1").value = "confident";
    document.querySelector(".ex1q2").value = "creativity";
    document.querySelector(".ex1q3").value = "independence";
    document.querySelector(".ex1q4").value = "enthusiastic";
    document.querySelector(".ex1q5").value = "optimistic";
    document.querySelector(".ex1q6").value = "competence";
    document.querySelector(".ex1q1").style.color = "green";
    document.querySelector(".ex1q2").style.color = "green";
    document.querySelector(".ex1q3").style.color = "green";
    document.querySelector(".ex1q4").style.color = "green";
    document.querySelector(".ex1q5").style.color = "green";
    document.querySelector(".ex1q6").style.color = "green";
    setex1q1("confident");
    setex1q2("creativity");
    setex1q3("independence");
    setex1q4("enthusiastic");
    setex1q5("optimistic");
    setex1q6("competence");
  };

  function scrollToTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  const checkbox1 = document.querySelector("#checkbox1");
  const checkbox2 = document.querySelector("#checkbox2");
  const checkbox3 = document.querySelector("#checkbox3");

  const handleEx2 = () => {
    if (checkedIndex1 === 0) {
      console.log("Motivated is selected");
    }
    if (checkedIndex1 === 1) {
      console.log("Sociability is selected");
    }
    if (checkedIndex1 === 2) {
      console.log("Tolerance is selected");
    }
    if (checkedIndex2 === 3) {
      console.log("imaginative is selected");
    }
    if (checkedIndex2 === 4) {
      console.log("audacious is selected");
    }
    if (checkedIndex2 === 5) {
      console.log("flexible is selected");
    }
  };

  const [checkedIndex1, setCheckedIndex1] = useState(null);
  const handleCheckboxChange1 = (index) => {
    if (checkedIndex1 === index) {
      setCheckedIndex1(null); // Uncheck if the same checkbox is clicked
    } else {
      setCheckedIndex1(index); // Update to the new checkbox index
      // alert(`Checkbox ${index + 1} has been checked!`); // Alert on check
    }
  };

  const [checkedIndex2, setCheckedIndex2] = useState(null);
  const handleCheckboxChange2 = (index) => {
    if (checkedIndex2 === index) {
      setCheckedIndex2(null); // Uncheck if the same checkbox is clicked
    } else {
      setCheckedIndex2(index); // Update to the new checkbox index
      // alert(`Checkbox ${index + 1} has been checked!`); // Alert on check
    }
  };

  //
  const [checkedIndex3, setCheckedIndex3] = useState(null);
  const handleCheckboxChange3 = (index) => {
    if (checkedIndex3 === index) {
      setCheckedIndex3(null); // Uncheck if the same checkbox is clicked
    } else {
      setCheckedIndex3(index); // Update to the new checkbox index
      // alert(`Checkbox ${index + 1} has been checked!`); // Alert on check
    }
  };

  const [checkedIndex4, setCheckedIndex4] = useState(null);
  const handleCheckboxChange4 = (index) => {
    if (checkedIndex4 === index) {
      setCheckedIndex4(null); // Uncheck if the same checkbox is clicked
    } else {
      setCheckedIndex4(index); // Update to the new checkbox index
      // alert(`Checkbox ${index + 1} has been checked!`); // Alert on check
    }
  };

  const ShowEx2 = () => {
    document.querySelector(".motivated").style.border = "2px solid green";
    document.querySelector(".motivated").style.padding = "5px";
    document.querySelector(".audacious").style.border = "2px solid green";
    document.querySelector(".audacious").style.padding = "5px";
    document.querySelector(".bravery").style.border = "2px solid green";
    document.querySelector(".bravery").style.padding = "5px";
    document.querySelector(".adventure").style.border = "2px solid green";
    document.querySelector(".adventure").style.padding = "5px";
  };
  const ClearEx2 = () => {
    document.querySelectorAll(".option input").forEach((checks) => {
      checks.checked = false;
      checks.style.color = "black";
    });
    document.querySelectorAll(".option").forEach((checks) => {
      checks.style.border = "none";
    });
  };

  //

  const [B1, setB1] = useState("");
  const [D2, setD2] = useState("");
  const [E3, setE3] = useState("");
  const [C4, setC4] = useState("");
  const [A5, setA5] = useState("");

  const ShowEx3 = () => {
    document.querySelector(".ex3__answers__bottom").style.display = "flex";
  };

  return (
    <div>
      <header id="free__header">
        {" "}
        <a href="https://toustousacademy.com/">
          <img src={logo} alt="" />
        </a>
        <ul>
          <a href="/" onClick={scrollToTop}>
            <li>Home</li>
          </a>
          <a href="/#about">
            <li>About</li>
          </a>
          <a href="/#feedback">
            <li>Feedback</li>
          </a>
        </ul>
      </header>

      <iframe className="irr__pdf" src={descPdf} frameborder="0"></iframe>
    </div>
  );
}

export default WritingExercises;
