import React from "react";
import "./WhyUs.css";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import LocalLibraryIcon from "@mui/icons-material/LocalLibrary";
import ClassIcon from "@mui/icons-material/Class";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import whyus1 from "./homepage123/image1.jpg";
import whyus2 from "./homepage123/image2.jpg";
import whyus3 from "./homepage123/image3.jpg";
import whyus4 from "./homepage123/image4.jpg";
import InterpreterModeIcon from '@mui/icons-material/InterpreterMode';
function WhyUs() {
  return (
    <div className="whyUs">
      <div className="whyus__right">
        <div>
          <img src={whyus1} alt="" />
        </div>
        <div>
          {" "}
          <img src={whyus2} alt="" />
        </div>
        <div>
          <img src={whyus3} alt="" />
        </div>
        <div>
          {" "}
          <img src={whyus4} alt="" />
        </div>
      </div>
      <div dir="rtl" className="whyus__left">
        <h1>لماذا تختار أكاديمية توستوس؟</h1>
        <p className="firstPara">
          في عوض متفرج ساعتين في لايف باش تفهم درس واحد، المنصة كتقدم دروس مدتها
          لا تتجاوز 12 دقيقة كحد أقصى مع تصحيح التمارين.
        </p>
        <div className="whyus__benefit">
          <AccountBoxIcon className="whyus__icon" />
          <p> التواصل مباشرة مع الأسثاذ في أي وقت.</p>
        </div>
        <div className="whyus__benefit">
          <CalendarMonthIcon className="whyus__icon" />
          <p>الإستفادة من ملخص الدرس بصيغة pdf.</p>
        </div>
        <div className="whyus__benefit">
          <LocalLibraryIcon className="whyus__icon" />
          <p>الاشتغال على تمارين داخل القسم وإمتحانات وطنية.</p>
        </div>
        <div className="whyus__benefit">
          <ClassIcon className="whyus__icon" />
          <p>
            الحصول على منهجيات جاهزة تساعد التلميذ على الكتابة بشكل جيد
            واحترافي.
          </p>
        </div>
        <div className="whyus__benefit">
          <AttachMoneyIcon className="whyus__icon" />
          <p>أثمنة جد مناسبة في متناول الجميع.</p>
        </div>
        <div className="whyus__benefit">
          <InterpreterModeIcon className="whyus__icon"/>
          <p>
            {" "}
            حصص مباشرة كل أسبوعين للدردشة وحل المشاكل التي تواجه التلميذ.
          </p>{" "}
        </div>
        <a href="#pricing"><button className="enrollbtn">إشترك الآن</button></a>
      </div>
    </div>
  );
}

export default WhyUs;
