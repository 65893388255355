import React, { useEffect } from "react";
import "./Hero.css";
import Header from "./Header";
import boy from "./boy.png";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";

function Hero() {
  try {
    window.onscroll = function () {
      const header = document.getElementById("header");
      const headerLogo = document.querySelector("#header img");

      if (window.scrollY > 50) {
        // Change 30 to your desired scroll distance
        header.style.background = "#253E56";
        headerLogo.style.width = "60px";
        header.style.padding = "10px 100px";
        header.style.transition = "background 0.3s ease-in-out";
      } else {
        if (window.innerWidth < "668") {
          headerLogo.style.width = "60px";
          header.style.background = "";
          header.style.padding = "20px 100px";
        } else {
          headerLogo.style.width = "60px";
          header.style.background = "";
        }
      }
    };
  } catch (error) {}

  useEffect(() => {
    const header = document.getElementById("header");
    const headerLogo = document.querySelector("#header img");
    if (window.location.href === "https://toustousacademy.com/") {
      header.style.background = "";
      headerLogo.style.width = "110px";
      header.style.padding = "20px 100px";
    }
    if (window.innerWidth < 760) {
      header.style.background = "";
      headerLogo.style.width = "60px";
      header.style.padding = "20px 100px";
    }

    if (window.location.href === "https://toustousacademy.com/book") {
      header.style.background = "#252B34";
      headerLogo.style.width = "60px";
      header.style.padding = "10px 100px";
      header.style.transition = "background 0.3s ease-in-out";
    }

    // headerLogo.style.width = "60px";
    // header.style.backgroundColor = "blue";
  }, []);
  function scrollToTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
  useEffect(() => {
    scrollToTop();
  });
  // useEffect(() => {
  //   AOS.init({
  //     once: true,
  //     // disable: "phone",
  //     duration: 1000,
  //     easing: "ease-out-cubic",
  //   });
  // }, []);
  return (
    <div className="hero" id="home">
      <Header />

      <div className="hero__content">
        {/* <h3 data-aos="fade-down">TousTous Academy</h3> */}
        <h1>
          Your avenue to <span>exceptional</span> results.
        </h1>
        <div>Unlock Your Potential With Our Courses</div>
        <Link to="#courses">
          <button className="getStarted">Get started</button>
        </Link>
        <Link to="/login">
          <button className="loginBtn">Log in</button>
        </Link>
      </div>

      <div className="hero__bottom">
        <div className="title__description">
          <h1>
            01.
            <span> Online learning</span>
          </h1>
          <p>
          Distance doesn’t limit your potential. Our online courses bring education to you.
          </p>
        </div>
        <div className="title__description">
          <h1>
            02.
            <span> Hands-on expertise</span>
          </h1>
          <p>
          Experience is the best teacher, and we’re here to guide you through it.
          </p>
        </div>
        <div className="title__description">
          <h1>
            03.
            <span> Career growth</span>
          </h1>
          <p>
          Your career growth begins with the skills you acquire today. keep learning.
          </p>
        </div>

        <iframe
          src="https://www.youtube.com/embed/XrxsmSRTwsQ?si=0Eby3oeppbUvIgbd"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          // allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
}

export default Hero;
