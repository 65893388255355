import React from "react";
import "./Pricing.css";
function Pricing() {
  return (
    <div className="pricing" id="pricing">
      <h1>Pricing</h1>
      <div className="pricing__container">
        <div className="pricing__card">
          <h3>نصف دورة</h3>
          <h5 dir="rtl">200 درهم</h5>
          <del dir="rtl">250 درهم</del>
          <p dir="rtl">
            بغيتي تجرب المنصة وتعرف واش غا تناسبك؟ اشترك دابا بنص الثمن واستافد
            من الدورة الأولى أو الثانية باش تحقق نتائج زوينة فمدة قصيرة!
          </p>
          <a href="https://api.whatsapp.com/send?phone=212718252186&text=بغيت%20نستافد%20من%20إشتراك%20نصف%20دورة.%20كيفاش%20نقدر%20نخلص%20الثمن؟" target="_blank">
            <button>إشترك الآن</button>
          </a>
        </div>
        <div className="pricing__card annual">
          <h3>العام كامل</h3>
          <h5 dir="rtl">350 درهم</h5>
          <del dir="rtl">500 درهم</del>
          <p dir="rtl">
            تسجل فالعرض ديال العام كامل نتا وصحابك المفضل بثمن رمزي جدًا ،وكل
            واحد فيكم غيكون عندو حساب خاص داخل المنصة.
          </p>{" "}
          <a href="https://api.whatsapp.com/send?phone=212718252186&text=بغيت%20نستافد%20من%20الإشتراك%20السنوي%20أنا%20وصاحبي.%20كيفاش%20نقدر%20نخلص%20الثمن؟" target="_blank">
            <button>إشترك الآن</button>
          </a>
        </div>
        <div className="pricing__card">
          <h3>إشتراك شهري</h3>
          <h5 dir="rtl">49 درهم</h5>
          <del dir="rtl">100 درهم</del>
          <p dir="rtl">
            جرب الاشتراك الشهري بثمن مناسب وتعلم على خاطرك! تقدر توقف أو تكمل
            فاش بغيتي، نتا اللي كتقرر فالمسار الدراسي ديالك.
          </p>
          <a href="https://api.whatsapp.com/send?phone=212718252186&text=بغيت%20نستافد%20من%20الإشتراك%20الشهري.%20كيفاش%20نقدر%20نخلص%20الثمن؟" target="_blank">
            <button>إشترك الآن</button>
          </a>
        </div>
      </div>
    </div>
  );
}

export default Pricing;
