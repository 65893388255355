import React, { useEffect } from "react";
import "./Header.css";
import logo from "./hassan-documents/white-logo.png";
import { Link } from "react-router-dom";

function Header() {
  setTimeout(function () {
    const header = document.getElementById("header");
    const headerLogo = document.querySelector("#header a img");
    headerLogo.style.width = "110px";
  },50)
  useEffect(() => {
    try {
      window.onscroll = function () {
        const header = document.getElementById("header");
        const headerLogo = document.querySelector("#header img");

        if (window.scrollY > 50) {
          // Change 30 to your desired scroll distance
          header.style.background = "#253E56";
          headerLogo.style.width = "60px";
          header.style.padding = "10px 100px";
          header.style.transition = "background 0.3s ease-in-out";
        } else {
          if (window.innerWidth < "668") {
            headerLogo.style.width = "60px";
            header.style.background = "";
            header.style.padding = "20px 100px";
          } else {
            headerLogo.style.width = "110px";
            header.style.background = "";
          }
        }
      };
    } catch (error) {}
  }, []);

  // Add event listener for resize

  // Initial check on page load

  useEffect(() => {
    const header = document.getElementById("header");
    const headerLogo = document.querySelector("#header a img");
    if (
      window.location.href === "https://toustousacademy.com/" &&
      window.innerWidth > 760
    ) {
      header.style.background = "";
      headerLogo.style.width = "110px";
      header.style.padding = "20px 100px";
    }

    if (
      window.location.href === "https://toustousacademy.com/" &&
      window.innerWidth < 760
    ) {
      header.style.background = "";
      headerLogo.style.width = "60px";
      header.style.padding = "20px";
    }

    // headerLogo.style.width = "60px";
    // header.style.backgroundColor = "blue";
  }, []);

  useEffect(() => {
    const header = document.getElementById("header");
    const headerLogo = document.querySelector("#header a img");
    if (window.location.href === "https://toustousacademy.com/") {
      header.style.background = "";
      headerLogo.style.width = "60px";
      header.style.padding = "20px 100px";
    }
  }, []);
  function scrollToTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
  useEffect(() => {
    scrollToTop();
  });
  return (
    <div>
      <header id="header">
        <a href="https://toustousacademy.com/">
          <img src={logo} alt="" />
        </a>
        <ul>
          <a href="https://toustousacademy.com/" onClick={scrollToTop}>
            <li>Home</li>
          </a>

          <a href="/#about">
            <li>About</li>
          </a>
          <a href="/#feedback">
            <li>Feedback</li>
          </a>

          {/* <li>Log in</li> */}
        </ul>
      </header>
    </div>
  );
}

export default Header;
