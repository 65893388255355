import React, { useEffect, useState } from "react";
import "./VocabularyExercises.css";
import logo from "./hassan-documents/bluelogo.png";
import BookHeader from "./BookHeader";
function GrammarExercises() {
  const [grammarex1q1, setgrammarex1q1] = useState("");
  const [grammarex1q2, setgrammarex1q2] = useState("");
  const [grammarex1q3, setgrammarex1q3] = useState("");
  const [grammarex1q4, setgrammarex1q4] = useState("");
  const [grammarex1q5, setgrammarex1q5] = useState("");
  const [grammarex1q6, setgrammarex1q6] = useState("");

  // State Management (Example)
  const [grammarex2q1, setGrammarex2q1] = useState("");
  const [grammarex2q2, setGrammarex2q2] = useState("");
  const [grammarex2q3, setGrammarex2q3] = useState("");
  const [grammarex2q4, setGrammarex2q4] = useState("");
  const [grammarex2q5, setGrammarex2q5] = useState("");
  const [grammarex2q6, setGrammarex2q6] = useState("");

  // Functions for checking answers, clearing inputs, and showing correct answers

  // // checkboxes
  const [isChecked1, setIsChecked1] = useState(false);
  const [isChecked2, setIsChecked2] = useState(false);
  const [isChecked3, setIsChecked3] = useState(false);
  //  const [checkbox1,setCheckbox1] = useState(null);
  //  const [checkbox2,setCheckbox2] = useState(null);
  //  const [checkbox3,setCheckbox3] = useState(null);
  useEffect(() => {
    // Scroll to the top of the page
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    const header = document.getElementById("free__header");
    const headerLogo = document.querySelector("#free__header img");

    if (window.scrollY > 50) {
      // Change 30 to your desired scroll distance

      headerLogo.style.width = "60px";
      header.style.padding = "10px 100px";
      header.style.transition = "background 0.3s ease-in-out";
    } else {
      if (window.innerWidth < "668") {
        headerLogo.style.width = "60px";
        header.style.background = "white";
        header.style.padding = "20px 100px";
      } else {
        headerLogo.style.width = "60px";
        header.style.background = "white";
      }
    }
  }, []);
  window.onscroll = function () {
    const header = document.getElementById("free__header");
    const headerLogo = document.querySelector("#free__header img");

    if (window.scrollY > 50) {
      // Change 30 to your desired scroll distance

      headerLogo.style.width = "60px";
      header.style.padding = "10px 100px";
      header.style.transition = "background 0.3s ease-in-out";
    } else {
      if (window.innerWidth < "668") {
        headerLogo.style.width = "60px";
        header.style.background = "white";
        header.style.padding = "20px 100px";
      } else {
        headerLogo.style.width = "60px";
        header.style.background = "white";
      }
    }
  };
  function scrollToTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  // checkGrammarEx1
  const checkGrammarEx1 = () => {
    if (grammarex1q1.toLocaleLowerCase() === "talking") {
      document.querySelector(".grammarex1q1").style.border = "1px solid green";
    } else {
      document.querySelector(".grammarex1q1").style.border = "1px solid red";
    }

    if (grammarex1q2.toLocaleLowerCase() === "meeting") {
      document.querySelector(".grammarex1q2").style.border = "1px solid green";
    } else {
      document.querySelector(".grammarex1q2").style.border = "1px solid red";
    }

    if (grammarex1q3.toLocaleLowerCase() === "learning") {
      document.querySelector(".grammarex1q3").style.border = "1px solid green";
    } else {
      document.querySelector(".grammarex1q3").style.border = "1px solid red";
    }

    if (grammarex1q4.toLocaleLowerCase() === "to go") {
      document.querySelector(".grammarex1q4").style.border = "1px solid green";
    } else {
      document.querySelector(".grammarex1q4").style.border = "1px solid red";
    }

    if (grammarex1q5.toLocaleLowerCase() === "to help") {
      document.querySelector(".grammarex1q5").style.border = "1px solid green";
    } else {
      document.querySelector(".grammarex1q5").style.border = "1px solid red";
    }

    if (grammarex1q6.toLocaleLowerCase() === "waiting") {
      document.querySelector(".grammarex1q6").style.border = "1px solid green";
    } else {
      document.querySelector(".grammarex1q6").style.border = "1px solid red";
    }
    console.log(grammarex1q1);
    console.log(grammarex1q2);
    console.log(grammarex1q3);
    console.log(grammarex1q4);
    console.log(grammarex1q5);
    console.log(grammarex1q6);
  };

  const checkGrammarEx2 = () => {
    if (grammarex2q1.toLocaleLowerCase() === "speak") {
      document.querySelector(".grammarex2q1").style.border = "1px solid green";
    } else {
      document.querySelector(".grammarex2q1").style.border = "1px solid red";
    }

    if (grammarex2q2.toLocaleLowerCase() === "arrive") {
      document.querySelector(".grammarex2q2").style.border = "1px solid green";
    } else {
      document.querySelector(".grammarex2q2").style.border = "1px solid red";
    }

    if (grammarex2q3.toLocaleLowerCase() === "to visit") {
      document.querySelector(".grammarex2q3").style.border = "1px solid green";
    } else {
      document.querySelector(".grammarex2q3").style.border = "1px solid red";
    }

    if (grammarex2q4.toLocaleLowerCase() === "to give") {
      document.querySelector(".grammarex2q4").style.border = "1px solid green";
    } else {
      document.querySelector(".grammarex2q4").style.border = "1px solid red";
    }

    if (grammarex2q5.toLocaleLowerCase() === "to win") {
      document.querySelector(".grammarex2q5").style.border = "1px solid green";
    } else {
      document.querySelector(".grammarex2q5").style.border = "1px solid red";
    }

    if (
      grammarex2q6.toLocaleLowerCase() === "to call" ||
      grammarex2q6.toLocaleLowerCase() === "calling"
    ) {
      document.querySelector(".grammarex2q6").style.border = "1px solid green";
    } else {
      document.querySelector(".grammarex2q6").style.border = "1px solid red";
    }

    console.log(grammarex2q1);
    console.log(grammarex2q2);
    console.log(grammarex2q3);
    console.log(grammarex2q4);
    console.log(grammarex2q5);
    console.log(grammarex2q6);
  };

  const clearGrammarEx1 = () => {
    setgrammarex1q1("");
    setgrammarex1q2("");
    setgrammarex1q3("");
    setgrammarex1q4("");
    setgrammarex1q5("");
    setgrammarex1q6("");
    document.querySelector(".grammarex1q1").textContent = "";
    document.querySelector(".grammarex1q2").textContent = "";
    document.querySelector(".grammarex1q3").textContent = "";
    document.querySelector(".grammarex1q4").textContent = "";
    document.querySelector(".grammarex1q5").textContent = "";
    document.querySelector(".grammarex1q6").textContent = "";
  };
  const clearGrammarEx2 = () => {
    setGrammarex2q1("");
    setGrammarex2q2("");
    setGrammarex2q3("");
    setGrammarex2q4("");
    setGrammarex2q5("");
    setGrammarex2q6("");

    document.querySelector(".grammarex2q1").value = "";
    document.querySelector(".grammarex2q2").value = "";
    document.querySelector(".grammarex2q3").value = "";
    document.querySelector(".grammarex2q4").value = "";
    document.querySelector(".grammarex2q5").value = "";
    document.querySelector(".grammarex2q6").value = "";
  };
  const showGrammarEx1 = () => {
    document.querySelector(".grammarex1q1").value = "talking";
    document.querySelector(".grammarex1q2").value = "meeting";
    document.querySelector(".grammarex1q3").value = "learning";
    document.querySelector(".grammarex1q4").value = "to go";
    document.querySelector(".grammarex1q5").value = "to help";
    document.querySelector(".grammarex1q6").value = "waiting";

    document.querySelector(".grammarex1q1").style.color = "green";
    document.querySelector(".grammarex1q2").style.color = "green";
    document.querySelector(".grammarex1q3").style.color = "green";
    document.querySelector(".grammarex1q4").style.color = "green";
    document.querySelector(".grammarex1q5").style.color = "green";
    document.querySelector(".grammarex1q6").style.color = "green";
    setgrammarex1q1("talking");
    setgrammarex1q2("meeting");
    setgrammarex1q3("learning");
    setgrammarex1q4("to go");
    setgrammarex1q5("to help");
    setgrammarex1q6("waiting");
  };
  const showGrammarEx2 = () => {
    document.querySelector(".grammarex2q1").value = "speak";
    document.querySelector(".grammarex2q2").value = "arrive";
    document.querySelector(".grammarex2q3").value = "to visit";
    document.querySelector(".grammarex2q4").value = "to give";
    document.querySelector(".grammarex2q5").value = "to win";
    document.querySelector(".grammarex2q6").value = "to call/calling"; // or "calling"

    // Change text color to green to indicate correct answers
    document.querySelector(".grammarex2q1").style.color = "green";
    document.querySelector(".grammarex2q2").style.color = "green";
    document.querySelector(".grammarex2q3").style.color = "green";
    document.querySelector(".grammarex2q4").style.color = "green";
    document.querySelector(".grammarex2q5").style.color = "green";
    document.querySelector(".grammarex2q6").style.color = "green";

    // Update state values as well
    setGrammarex2q1("speak");
    setGrammarex2q2("arrive");
    setGrammarex2q3("to visit");
    setGrammarex2q4("to give");
    setGrammarex2q5("to win");
    setGrammarex2q6("to call/calling"); // or "calling"
  };

  function scrollToTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  const checkbox1 = document.querySelector("#checkbox1");
  const checkbox2 = document.querySelector("#checkbox2");
  const checkbox3 = document.querySelector("#checkbox3");

  const handleEx2 = () => {
    if (checkedIndex1 === 0) {
      console.log("Motivated is selected");
    }
    if (checkedIndex1 === 1) {
      console.log("Sociability is selected");
    }
    if (checkedIndex1 === 2) {
      console.log("Tolerance is selected");
    }
    if (checkedIndex2 === 3) {
      console.log("imaginative is selected");
    }
    if (checkedIndex2 === 4) {
      console.log("audacious is selected");
    }
    if (checkedIndex2 === 5) {
      console.log("flexible is selected");
    }
  };

  const [checkedIndex1, setCheckedIndex1] = useState(null);
  const handleCheckboxChange1 = (index) => {
    if (checkedIndex1 === index) {
      setCheckedIndex1(null); // Uncheck if the same checkbox is clicked
    } else {
      setCheckedIndex1(index); // Update to the new checkbox index
      // alert(`Checkbox ${index + 1} has been checked!`); // Alert on check
    }
  };

  const [checkedIndex2, setCheckedIndex2] = useState(null);
  const handleCheckboxChange2 = (index) => {
    if (checkedIndex2 === index) {
      setCheckedIndex2(null); // Uncheck if the same checkbox is clicked
    } else {
      setCheckedIndex2(index); // Update to the new checkbox index
      // alert(`Checkbox ${index + 1} has been checked!`); // Alert on check
    }
  };

  //
  const [checkedIndex3, setCheckedIndex3] = useState(null);
  const handleCheckboxChange3 = (index) => {
    if (checkedIndex3 === index) {
      setCheckedIndex3(null); // Uncheck if the same checkbox is clicked
    } else {
      setCheckedIndex3(index); // Update to the new checkbox index
      // alert(`Checkbox ${index + 1} has been checked!`); // Alert on check
    }
  };

  const [checkedIndex4, setCheckedIndex4] = useState(null);
  const handleCheckboxChange4 = (index) => {
    if (checkedIndex4 === index) {
      setCheckedIndex4(null); // Uncheck if the same checkbox is clicked
    } else {
      setCheckedIndex4(index); // Update to the new checkbox index
      // alert(`Checkbox ${index + 1} has been checked!`); // Alert on check
    }
  };
  const [checkedIndex5, setCheckedIndex5] = useState(null);
  const handleCheckboxChange5 = (index) => {
    if (checkedIndex5 === index) {
      setCheckedIndex5(null); // Uncheck if the same checkbox is clicked
    } else {
      setCheckedIndex5(index); // Update to the new checkbox index
      // alert(`Checkbox ${index + 1} has been checked!`); // Alert on check
    }
  };
  const [checkedIndex6, setCheckedIndex6] = useState(null);
  const handleCheckboxChange6 = (index) => {
    if (checkedIndex6 === index) {
      setCheckedIndex6(null); // Uncheck if the same checkbox is clicked
    } else {
      setCheckedIndex6(index); // Update to the new checkbox index
      // alert(`Checkbox ${index + 1} has been checked!`); // Alert on check
    }
  };

  const ShowEx2 = () => {
    document.querySelector(".motivated").style.border = "2px solid green";
    document.querySelector(".motivated").style.padding = "5px";
    document.querySelector(".audacious").style.border = "2px solid green";
    document.querySelector(".audacious").style.padding = "5px";
    document.querySelector(".bravery").style.border = "2px solid green";
    document.querySelector(".bravery").style.padding = "5px";
    document.querySelector(".adventure").style.border = "2px solid green";
    document.querySelector(".adventure").style.padding = "5px";
  };
  const ClearEx2 = () => {
    document.querySelectorAll(".option input").forEach((checks) => {
      checks.checked = false;
      checks.style.color = "black";
    });
    document.querySelectorAll(".option").forEach((checks) => {
      checks.style.border = "none";
    });
  };

  //

  const [B1, setB1] = useState("");
  const [D2, setD2] = useState("");
  const [E3, setE3] = useState("");
  const [C4, setC4] = useState("");
  const [A5, setA5] = useState("");

  const ShowEx3 = () => {
    document.querySelector(".ex3__answers__bottom").style.display = "flex";
  };

  return (
    <div>
      <header id="free__header">
        {" "}
        <a href="https://toustousacademy.com/">
          <img src={logo} alt="" />
        </a>
        <ul>
          <a href="/" onClick={scrollToTop}>
            <li>Home</li>
          </a>
          <a href="/#about">
            <li>About</li>
          </a>
          <a href="/#feedback">
            <li>Feedback</li>
          </a>
        </ul>
      </header>

      <div className="free__vocabulary__container">
        {/* <div className="iframe">
          <iframe
            src="https://www.youtube.com/embed/zr24DDbd76A?si=kid27m2bwxT9pV41"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        </div> */}
        <div className="exercice1">
          <p>
            Exercise 1 : Fill in the blank with the correct form of the word in
            parentheses:
          </p>
          <ol>
            {" "}
            <li>
              {" "}
              1- She avoids <b>(talk)</b>{" "}
              <input
                type="text"
                className="grammarex1q1"
                onChange={(e) => setgrammarex1q1(e.target.value)}
                value={grammarex1q1}
              />{" "}
              to strangers.{" "}
            </li>{" "}
            <li>
              {" "}
              2- I look forward to <b>(meet)</b>{" "}
              <input
                type="text"
                className="grammarex1q2"
                onChange={(e) => setgrammarex1q2(e.target.value)}
                value={grammarex1q2}
              />{" "}
              you soon.{" "}
            </li>{" "}
            <li>
              {" "}
              3- They are interested in <b>(learn)</b>{" "}
              <input
                type="text"
                className="grammarex1q3"
                onChange={(e) => setgrammarex1q3(e.target.value)}
                value={grammarex1q3}
              />{" "}
              Spanish.{" "}
            </li>{" "}
            <li>
              {" "}
              4- I decided <b>(go)</b>{" "}
              <input
                type="text"
                className="grammarex1q4"
                onChange={(e) => setgrammarex1q4(e.target.value)}
                value={grammarex1q4}
              />{" "}
              to the beach this weekend.{" "}
            </li>{" "}
            <li>
              {" "}
              5- He promised <b>(help)</b>{" "}
              <input
                type="text"
                className="grammarex1q5"
                onChange={(e) => setgrammarex1q5(e.target.value)}
                value={grammarex1q5}
              />{" "}
              me with my project.{" "}
            </li>{" "}
            <li>
              {" "}
              6- She can't stand <b>(wait)</b>{" "}
              <input
                type="text"
                className="grammarex1q6"
                onChange={(e) => setgrammarex1q6(e.target.value)}
                value={grammarex1q6}
              />{" "}
              for long hours.{" "}
            </li>{" "}
          </ol>
          <button onClick={() => checkGrammarEx1()}>Check</button>
          <button onClick={() => clearGrammarEx1()}>Clear</button>
          <button onClick={() => showGrammarEx1()}>Show</button>
        </div>
        <div className="exercice2">
          <p>Exercise 2 : Choose the right answers from the options giving.</p>

          <ol>
            <li>
              1- He can <b>(speak)</b>{" "}
              <input
                type="text"
                className="grammarex2q1"
                onChange={(e) => setGrammarex2q1(e.target.value)}
                value={grammarex2q1}
              />{" "}
              three languages fluently.
            </li>
            <li>
              2- They might <b>(arrive)</b>{" "}
              <input
                type="text"
                className="grammarex2q2"
                onChange={(e) => setGrammarex2q2(e.target.value)}
                value={grammarex2q2}
              />{" "}
              late due to traffic.
            </li>
            <li>
              3- They want <b>(visit)</b>{" "}
              <input
                type="text"
                className="grammarex2q3"
                onChange={(e) => setGrammarex2q3(e.target.value)}
                value={grammarex2q3}
              />{" "}
              the new museum.
            </li>
            <li>
              4- She refused <b>(give)</b>{" "}
              <input
                type="text"
                className="grammarex2q4"
                onChange={(e) => setGrammarex2q4(e.target.value)}
                value={grammarex2q4}
              />{" "}
              any details.
            </li>
            <li>
              5- We hope <b>(win)</b>{" "}
              <input
                type="text"
                className="grammarex2q5"
                onChange={(e) => setGrammarex2q5(e.target.value)}
                value={grammarex2q5}
              />{" "}
              the competition.
            </li>
            <li>
              6- Don't forget <b>(call)</b>{" "}
              <input
                type="text"
                className="grammarex2q6"
                onChange={(e) => setGrammarex2q6(e.target.value)}
                value={grammarex2q6}
              />{" "}
              me when you arrive.
            </li>
          </ol>

          <button onClick={() => checkGrammarEx2()}>Check</button>
          <button onClick={() => clearGrammarEx2()}>Clear</button>
          <button onClick={() => showGrammarEx2()}>Show</button>
        </div>
      </div>
    </div>
  );
}

export default GrammarExercises;
